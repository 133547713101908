import { css } from "@emotion/react";
import NextLink from "next/link";
import React from "react";
import { Color, LineHeight } from "../../../../tokens";

type Props = {
  href: string;
  children: React.ReactNode;
  isBold?: boolean;
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "onClick">;

const TextLink: React.FC<Props> = ({
  href,
  isBold,
  children,
  ...anchorProps
}) => {
  return (
    <NextLink
      {...anchorProps}
      href={href}
      css={linkStyle(isBold)}
      onClick={(e) => {
        // このリンクが載っているコンポーネントにイベントを発火させないため。
        e.stopPropagation();
      }}
    >
      {children}
    </NextLink>
  );
};

const linkStyle = (isBold?: boolean) => css`
  font-weight: ${isBold ? "bold" : "normal"};
  line-height: ${LineHeight[140]};
  color: ${Color.Primary.Base};
  &:hover {
    text-decoration: underline;
  }
`;

export default TextLink;
