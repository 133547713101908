import { useMemo } from "react";
import { useQuery } from "urql";
import {
  BuildingFormDocument,
  BuildingFormQuery,
  GraphQLBuildingImageType,
} from "../../../../../graphql";
import { BuildingFormFieldValuesType } from "../../../../../validations/schemas/buildingForm/types";

const convertBuildingData = (
  building: BuildingFormQuery["building"],
): BuildingFormFieldValuesType => ({
  buildingCode: building.buildingCode,
  lessorBranchId: building.lessorBranch?.id,
  name: building.name,
  nameReading: building.nameReading ?? undefined,
  oldNames: building.oldNames.map(({ name, renamedYear, renamedMonth }) => ({
    name,
    year: renamedYear,
    month: renamedMonth,
  })),
  prefecturePidanId: building.cityDataHub.ward.prefecture.id,
  wardPidanId: building.cityDataHub.ward.id,
  cityPidanId: building.cityDataHub.id,
  pidanBlock: building.pidanBlock ?? undefined,
  latitude: building.latitude ?? undefined,
  longitude: building.longitude ?? undefined,
  completedYear: building.completedYear,
  // FIXME: バックエンドを修正して non-null にする
  completedMonth: building.completedMonth ?? 1,
  renewalYear: building.renewalYear ?? undefined,
  renewalMonth: building.renewalMonth ?? undefined,
  groundfloor: building.groundfloor,
  basement: building.basement,
  structureType: building.structure?.structureType ?? undefined,
  structureText: building.structure?.structureText ?? undefined,
  standardFloorArea: building.standardFloorArea,
  grossFloorArea: building.grossFloorArea ?? undefined,
  buildingUse: building.buildingUse ?? undefined,
  earthquakeResistantStructure: building.earthquakeResistantStructure,
  janitor: building.janitor ?? undefined,
  selfLocking: building.selfLocking ?? undefined,
  security: building.security ?? undefined,
  parkingLotTotal: building.parkingLotTotal ?? undefined,
  parkingLotVacant: building.parkingLotVacant ?? undefined,
  elevatorNum: building.elevators?.num,
  elevatorCapacity: building.elevators?.capacity ?? undefined,
  elevatorAccessControl: building.elevators?.accessControl ?? undefined,
  airConditionType: building.airConditioner?.airConditionerType ?? undefined,
  airConditionText: building.airConditioner?.airConditionerText ?? undefined,
  powerCapacity: building.powerCapacity ?? undefined,
  powerCapacitySideNote: building.powerCapacitySideNote ?? undefined,
  emergencyGenerator: building.emergencyGenerator ?? undefined,
  explanation: building.explanation ?? undefined,
  internalExplanation: building.internalExplanation ?? undefined,
  netLeasableAreaTotal: building.netLeasableAreaTotal ?? undefined,
  lmLessorUserId: building.lmLessorUser?.id ?? undefined,
  pmLessorUserId: building.pmLessorUser?.id ?? undefined,
  nearbyStations: building.nearbyStations.map((station) => ({
    stationId: station.stationId,
    lineId: station.line?.id,
    timeRequired: station.timeRequired,
    exitName: station.exitName ?? undefined,
  })),
  buildingBrandId: building.brand?.id,
  buildingTagIds: building.tags.map((tag) => tag.id),
  buildingManagementTypeId: building.managementType?.id,
  standardUnitFloorHeight: building.standardUnitFloorHeight ?? undefined,
  standardUnitFloorHeightSideNote:
    building.standardUnitFloorHeightSideNote ?? undefined,
  standardUnitFloorLoading: building.standardUnitFloorLoading ?? undefined,
  standardUnitFloorLoadingSideNote:
    building.standardUnitFloorLoadingSideNote ?? undefined,
  standardUnitFloorMaterial:
    building.standardUnitFloorMaterial?.floorMaterialType,
  standardUnitFloorMaterialSideNote:
    building.standardUnitFloorMaterial?.floorMaterialText ?? undefined,
  standardUnitOaFloorType: building.standardUnitOaFloor?.oaFloorType,
  standardUnitOaFloorHeight:
    building.standardUnitOaFloor?.oaFloorHeight ?? undefined,
  buildingImageExteriorUrl: building.buildingImages.find(
    (image) => image.imageType === GraphQLBuildingImageType.Exterior,
  )?.url,
  buildingImageVicinityMapUrl: building.buildingImages.find(
    (image) => image.imageType === GraphQLBuildingImageType.VicinityMap,
  )?.url,
  facilityDetailTwentyFourSeven: building.facilityDetail.twentyFourSeven,
  facilityDetailWashlet: building.facilityDetail.washlet,
  facilityDetailSeparateWc: building.facilityDetail.separateWc,
  facilityDetailOpticalFiber: building.facilityDetail.opticalFiber,
  facilityDetailRefreshingSpace: building.facilityDetail.refreshingSpace,
  facilityDetailSmokingRoom: building.facilityDetail.smokingRoom,
  facilityDetailAlongMainStreet: building.facilityDetail.alongMainStreet,
  facilityDetailMuchSunshine: building.facilityDetail.muchSunshine,
  facilityDetailEco: building.facilityDetail.eco,
  facilityDetailNursery: building.facilityDetail.nursery,
  facilityDetailEmergencyPower: building.facilityDetail.emergencyPower,
  facilityDetailCafeteria: building.facilityDetail.cafeteria,
  facilityDetailDirectAccessToStation:
    building.facilityDetail.directAccessToStation,
  facilityDetailConcrete: building.facilityDetail.concrete,
  facilityDetailSoho: building.facilityDetail.soho,
  facilityDetailHighGrade: building.facilityDetail.highGrade,
});

const emptyInitialValues: Partial<BuildingFormFieldValuesType> = {
  // フォームの状態は validate 前の状態だが、最寄駅がない入力は許容しないので空オブジェクトを入れておく
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nearbyStations: [{} as any],
  // FieldArray なのでリセットする必要がある
  oldNames: [],

  // 他のフィールドはすべてリセットする
  lessorBranchId: undefined,
  name: undefined,
  nameReading: undefined,
  prefecturePidanId: undefined,
  wardPidanId: undefined,
  cityPidanId: undefined,
  pidanBlock: undefined,
  latitude: undefined,
  longitude: undefined,
  completedYear: undefined,
  completedMonth: undefined,
  renewalYear: undefined,
  renewalMonth: undefined,
  groundfloor: undefined,
  basement: undefined,
  structureType: undefined,
  structureText: undefined,
  standardFloorArea: undefined,
  grossFloorArea: undefined,
  buildingUse: undefined,
  earthquakeResistantStructure: undefined,
  janitor: undefined,
  selfLocking: undefined,
  security: undefined,
  parkingLotTotal: undefined,
  parkingLotVacant: undefined,
  elevatorNum: undefined,
  elevatorCapacity: undefined,
  elevatorAccessControl: undefined,
  airConditionType: undefined,
  airConditionText: undefined,
  powerCapacity: undefined,
  powerCapacitySideNote: undefined,
  emergencyGenerator: undefined,
  explanation: undefined,
  internalExplanation: undefined,
  netLeasableAreaTotal: undefined,
  lmLessorUserId: undefined,
  pmLessorUserId: undefined,
  buildingBrandId: undefined,
  buildingTagIds: undefined,
  buildingManagementTypeId: undefined,
  standardUnitFloorHeight: undefined,
  standardUnitFloorHeightSideNote: undefined,
  standardUnitFloorLoading: undefined,
  standardUnitFloorLoadingSideNote: undefined,
  standardUnitFloorMaterial: undefined,
  standardUnitFloorMaterialSideNote: undefined,
  standardUnitOaFloorType: undefined,
  standardUnitOaFloorHeight: undefined,
  buildingImageExteriorUrl: undefined,
  buildingImageVicinityMapUrl: undefined,
  facilityDetailTwentyFourSeven: false,
  facilityDetailWashlet: false,
  facilityDetailSeparateWc: false,
  facilityDetailOpticalFiber: false,
  facilityDetailRefreshingSpace: false,
  facilityDetailSmokingRoom: false,
  facilityDetailAlongMainStreet: false,
  facilityDetailMuchSunshine: false,
  facilityDetailEco: false,
  facilityDetailNursery: false,
  facilityDetailEmergencyPower: false,
  facilityDetailCafeteria: false,
  facilityDetailDirectAccessToStation: false,
  facilityDetailConcrete: false,
  facilityDetailSoho: false,
  facilityDetailHighGrade: false,
};

type BuildingInitialValueType = {
  isLoading: boolean;
  initialValues: Partial<BuildingFormFieldValuesType>;
};

export const useBuildingInitialValues = (
  buildingId?: number,
): BuildingInitialValueType => {
  const [{ data: buildingData }] = useQuery({
    query: BuildingFormDocument,
    variables: { id: buildingId ?? 0 },
    pause: !buildingId,
  });

  const initialValues = useMemo(() => {
    if (buildingId !== undefined && buildingData?.building !== undefined) {
      return convertBuildingData(buildingData?.building);
    } else {
      return emptyInitialValues;
    }
  }, [buildingId, buildingData]);

  return {
    initialValues,
    isLoading: !!buildingId && buildingData?.building?.id !== buildingId, // ビル id があるのに対応するビルデータが読み込まれていない場合はローディング中
  };
};
