import { FC, useState } from "react";
import Autocomplete from "../../../uiParts/dataEntry/Autocomplete";

type Props = {
  currentWard?: string;
  wards: { id: number; name: string }[];
  onSelect: (ward: { id: number; name: string }) => void;
  onDelete: () => void;
  isRequired?: boolean;
  testId?: string;
};

const CitySelect: FC<Props> = ({
  currentWard,
  wards,
  onSelect,
  onDelete,
  isRequired,
  testId,
}) => {
  const { results, searchText, setSearchText } = useWardsSearch(wards);

  return (
    <>
      <Autocomplete
        name="ward"
        label="市区町村"
        isRequired={isRequired}
        placeholder="千代田区"
        value={currentWard ? currentWard : searchText}
        onChange={(value) => setSearchText(value)}
        menuItems={results.map((ward) => {
          return {
            label: ward.name,
            onClick: () => onSelect(ward),
          };
        })}
        onDelete={() => {
          setSearchText("");
          onDelete();
        }}
        isSelected={Boolean(currentWard)}
        testId={testId}
      />
    </>
  );
};

const useWardsSearch = (wards: { id: number; name: string }[]) => {
  const [searchText, setSearchText] = useState<string>("");
  const results = wards.filter((ward) => ward.name.includes(searchText));
  return { results, searchText, setSearchText };
};

export default CitySelect;
