import { css, keyframes } from "@emotion/react";
import { ValueOf } from "next/dist/shared/lib/constants";
import React, { HTMLAttributes, useCallback, useRef } from "react";
import { Shadow, Spacing, ZIndex } from "../../../../tokens";

const verticalPositions = {
  TOP: "top",
  BOTTOM: "bottom",
} as const;

const horizontalPositions = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
} as const;

type Props = HTMLAttributes<HTMLDetailsElement> & {
  verticalPosition?: ValueOf<typeof verticalPositions>;
  horizontalPosition?: ValueOf<typeof horizontalPositions>;
  closingTarget?: "outside" | "anywhere";
  isContentFullWidth?: boolean;
  children: [React.ReactNode, React.ReactNode];
};

const Dropdown: React.FC<Props> = ({
  verticalPosition = verticalPositions["BOTTOM"],
  horizontalPosition = horizontalPositions["LEFT"],
  closingTarget = "outside",
  isContentFullWidth = true,
  children: [trigger, content],
  ...attributes
}) => {
  const dropdownRef = useRef<HTMLDetailsElement>(null);
  const onAnywhereClose = useCallback(() => {
    if (closingTarget === "outside" || !dropdownRef.current) {
      return;
    }

    dropdownRef.current.removeAttribute("open");
  }, [closingTarget]);

  return (
    <details {...attributes} css={dropdownStyle} ref={dropdownRef}>
      <summary css={dropdownTriggerStyle}>
        <div css={dropdownTriggerInnerStyle}>{trigger}</div>
      </summary>
      <div
        css={[
          dropdownContentStyle,
          mappedContentVerticalModifierStyles[verticalPosition],
          mappedContentHorizontalModifierStyles[horizontalPosition],
          isContentFullWidth && dropdownContentWithFullStyle,
        ]}
        onClick={onAnywhereClose}
      >
        <div css={dropdownContentInnerStyle}>{content}</div>
      </div>
    </details>
  );
};

const dropdownStyle = css`
  position: relative;
`;

const dropdownTriggerStyle = css`
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    cursor: default;
    z-index: ${ZIndex.Modal};
  }

  details[open] > &::before {
    display: block;
  }
`;

const dropdownTriggerInnerStyle = css`
  details[open] & {
    position: relative;
    z-index: ${ZIndex.Modal};
  }
`;

const dropdownContentStyle = css`
  position: absolute;
  display: none;
  z-index: ${ZIndex.Modal};

  details[open] > summary + & {
    display: block;
  }
`;

const dropdownContentWithFullStyle = css`
  min-width: 100%;
`;

const dropdownContentTopModifierStyle = css`
  bottom: 100%;
  margin-bottom: ${Spacing[4]};
`;
const dropdownContentBottomModifierStyle = css`
  top: 100%;
  margin-top: ${Spacing[4]};
`;

const mappedContentVerticalModifierStyles = {
  [verticalPositions.TOP]: dropdownContentTopModifierStyle,
  [verticalPositions.BOTTOM]: dropdownContentBottomModifierStyle,
};

const dropdownContentLeftModifierStyle = css`
  left: 0;
`;

const dropdownContentCenterModifierStyle = css`
  left: 50%;
  transform: translateX(-50%);
`;

const dropdownContentRightModifierStyle = css`
  right: 0;
`;

const mappedContentHorizontalModifierStyles = {
  [horizontalPositions.LEFT]: dropdownContentLeftModifierStyle,
  [horizontalPositions.CENTER]: dropdownContentCenterModifierStyle,
  [horizontalPositions.RIGHT]: dropdownContentRightModifierStyle,
};

const dropdownContentInnerShowingKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const dropdownContentInnerStyle = css`
  opacity: 0;
  box-shadow: ${Shadow.Box[1]};
  animation: 0.2s ease-in-out forwards;

  details[open] & {
    animation-name: ${dropdownContentInnerShowingKeyframes};
  }
`;

export default Dropdown;
