import { useQuery } from "urql";
import { PidanPrefecturesDocument, PrefecturesDocument } from "../graphql";

export const usePrefectures = () => {
  const [{ data: prefectures }] = useQuery({ query: PrefecturesDocument });
  return prefectures?.prefectures;
};

export const usePidanPrefectures = () => {
  const [{ data: prefectures }] = useQuery({ query: PidanPrefecturesDocument });
  return prefectures?.pidanPrefectures.map((prefecture) => ({
    id: Number(prefecture.id.replace("Prefecture/", "")),
    name: prefecture.name,
  }));
};
