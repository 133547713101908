import { useCallback, useMemo, useState } from "react";
import { useModalContext } from "../../../../contexts/Modal/context";

export const useBuildingModal = <T extends () => unknown | Promise<unknown>>(
  id: symbol,
  closeAfterCallback?: T,
) => {
  const { openModal: open } = useModalContext();

  const [buildingId, setBuildingId] = useState<number>();
  const [openTime, setOpenTime] = useState<Date>();

  const openModal = useCallback(
    (buildingId?: number) => {
      setBuildingId(buildingId);
      setOpenTime(new Date());
      open(id);
    },
    [open, id],
  );

  const modal = useMemo(() => {
    return {
      onCloseAfter: () => {
        closeAfterCallback?.();
      },
    };
  }, [closeAfterCallback]);

  return { openModal, modal, id, buildingId, openTime };
};
