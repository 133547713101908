import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import {
  faArrowUpRightFromSquare,
  faInfoCircle,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChangeEvent,
  ChangeEventHandler,
  ComponentProps,
  FC,
  FocusEvent,
  FocusEventHandler,
  FormEventHandler,
  MouseEventHandler,
  Ref,
} from "react";
import { Modal } from "../../../contexts/Modal";
import {
  GraphQLAirConditionerType,
  GraphQLFloorMaterialType,
  GraphQLOaFloorType,
  GraphQLStructureType,
} from "../../../graphql";
import { grid } from "../../../styles/mixin";
import { Color, LineHeight, RadiusSize, Spacing } from "../../../tokens";
import {
  BuildingFormFieldKeysType,
  BuildingFormFieldValuesType,
  BuildingNearbyStationFormFieldKeysType,
  BuildingNearbyStationFormFieldValuesType,
  BuildingOldNameFormFieldKeysType,
  BuildingOldNameFormFieldValuesType,
} from "../../../validations/schemas/buildingForm/types";
import PrefectureSelect from "../../organisms/lessor/property/AddressSelect";
import CitySelect from "../../organisms/lessor/property/CitySelect";
import LineSelect from "../../organisms/lessor/property/NearbyStationInput/LineSelect";
import WardSelect from "../../organisms/lessor/property/WardSelect";
import Heading from "../../uiParts/dataDisplay/Heading";
import { ModalForm } from "../../uiParts/dataDisplay/ModalForm";
import TagLabel from "../../uiParts/dataDisplay/TagLabel";
import Tooltip from "../../uiParts/dataDisplay/Tooltip";
import { TooltipDisplay } from "../../uiParts/dataDisplay/TooltipDisplay";
import Checkbox from "../../uiParts/dataEntry/Checkbox";
import Inputbox from "../../uiParts/dataEntry/Inputbox";
import Selectbox from "../../uiParts/dataEntry/Selectbox";
import SelectboxMultiple from "../../uiParts/dataEntry/SelectboxMultiple";
import Textarea from "../../uiParts/dataEntry/Textarea";
import UploadImage from "../../uiParts/dataEntry/UploadImage";
import ErrorMessage from "../../uiParts/feedback/ErrorMessage";
import FormField from "../../uiParts/layout/FormField";
import TextLink from "../../uiParts/navigation/TextLink";
import { StationSelect } from "./StationSearch";

type InputFieldProps<
  T extends HTMLElement,
  U extends keyof V,
  V = BuildingFormFieldValuesType,
> = {
  onChange: ChangeEventHandler<T>;
  onBlur: FocusEventHandler<T>;
  name: string;
  value: V[U];
};

type SelectFieldProps<U extends keyof V, V = BuildingFormFieldValuesType> = {
  onChange: ChangeEventHandler<HTMLSelectElement>;
  onBlur: FocusEventHandler<HTMLSelectElement>;
  name: string;
  value: V[U];
  options: ComponentProps<typeof Selectbox>["options"];
  resetValue: () => void;
};

type ChangeFieldProps<U extends keyof V, V = BuildingFormFieldValuesType> = {
  onChange: (value: V[U] | undefined) => void;
  // For revalidation
  onBlur: () => void;
  name: string;
  value: V[U];
};

type FieldArrayChangeFieldProps<U extends keyof V, V> = {
  onChange: (value: V[U] | undefined) => void;
  name: string;
  value: V[U];
};

type CheckBoxInputFieldProps<
  U extends keyof V,
  V = BuildingFormFieldValuesType,
> = {
  name: string;
  value: V[U];
  onToggle: () => void;
  ref?: Ref<HTMLInputElement>;
};

type FieldHandlerType = <
  T extends ChangeEvent<E> | FocusEvent<E>,
  E extends HTMLInputElement | HTMLSelectElement,
>(
  event: T,
) => void;

type InputFieldKeysType =
  | "name"
  | "pidanBlock"
  | "latitude"
  | "longitude"
  | "groundfloor"
  | "basement"
  | "structureText"
  | "grossFloorArea"
  | "netLeasableAreaTotal"
  | "security"
  | "elevatorNum"
  | "elevatorCapacity"
  | "parkingLotTotal"
  | "parkingLotVacant"
  | "powerCapacity"
  | "powerCapacitySideNote"
  | "airConditionText"
  | "emergencyGenerator"
  | "standardFloorArea"
  | "standardUnitFloorHeight"
  | "standardUnitFloorHeightSideNote"
  | "standardUnitFloorLoading"
  | "standardUnitFloorLoadingSideNote"
  | "standardUnitFloorMaterialSideNote"
  | "standardUnitOaFloorHeight";
type TextareaFieldKeysType = "explanation" | "internalExplanation";
type SelectFieldKeysType =
  | "structureType"
  | "buildingUse"
  | "earthquakeResistantStructure"
  | "janitor"
  | "selfLocking"
  | "airConditionType"
  | "elevatorAccessControl"
  | "standardUnitFloorMaterial"
  | "standardUnitOaFloorType"
  | "buildingBrandId"
  | "buildingManagementTypeId"
  | "lessorBranchId";
type ChangeFieldType =
  | "prefecturePidanId"
  | "wardPidanId"
  | "cityPidanId"
  | "completedYear"
  | "completedMonth"
  | "renewalYear"
  | "renewalMonth";
type ArrayFieldKeysType = "buildingImages" | "oldNames" | "nearbyStations";
type CheckBoxFieldKeysType =
  | "facilityDetailTwentyFourSeven"
  | "facilityDetailWashlet"
  | "facilityDetailSeparateWc"
  | "facilityDetailOpticalFiber"
  | "facilityDetailRefreshingSpace"
  | "facilityDetailSmokingRoom"
  | "facilityDetailAlongMainStreet"
  | "facilityDetailMuchSunshine"
  | "facilityDetailEco"
  | "facilityDetailNursery"
  | "facilityDetailEmergencyPower"
  | "facilityDetailCafeteria"
  | "facilityDetailDirectAccessToStation"
  | "facilityDetailConcrete"
  | "facilityDetailSoho"
  | "facilityDetailHighGrade";
type _OtherFieldKeysType = "buildingTagIds";

type Props = {
  modal: Omit<ComponentProps<typeof Modal>, "children">;
  fields: {
    [K in InputFieldKeysType]: InputFieldProps<HTMLInputElement, K>;
  } & {
    [K in TextareaFieldKeysType]: InputFieldProps<HTMLTextAreaElement, K>;
  } & {
    [K in SelectFieldKeysType]: SelectFieldProps<K>;
  } & {
    [K in ChangeFieldType]: ChangeFieldProps<K>;
  } & {
    [K in CheckBoxFieldKeysType]: CheckBoxInputFieldProps<K>;
  } & {
    buildingImageExteriorFile: ChangeFieldProps<
      "buildingImageExteriorFile",
      BuildingFormFieldValuesType
    >;
    buildingImageExteriorUrl: ChangeFieldProps<
      "buildingImageExteriorUrl",
      BuildingFormFieldValuesType
    >;
    buildingImageVicinityMapFile: ChangeFieldProps<
      "buildingImageVicinityMapFile",
      BuildingFormFieldValuesType
    >;
    buildingImageVicinityMapUrl: ChangeFieldProps<
      "buildingImageVicinityMapUrl",
      BuildingFormFieldValuesType
    >;
    buildingTagIds: {
      name: string;
      value: number[];
      options: ComponentProps<typeof SelectboxMultiple>["checkboxes"];
    };
    oldNames: {
      fields: {
        id: string;
        items: {
          name: { name: string; value: string | undefined };
          year: FieldArrayChangeFieldProps<
            "year",
            BuildingOldNameFormFieldValuesType
          >;
          month: FieldArrayChangeFieldProps<
            "month",
            BuildingOldNameFormFieldValuesType
          >;
        };
        onChange: FieldHandlerType;
        onBlur: FieldHandlerType;
        onRemove: MouseEventHandler;
      }[];
      onAppend: MouseEventHandler;
    };
    nearbyStations: {
      fields: {
        id: string;
        items: {
          lineId: FieldArrayChangeFieldProps<
            "lineId",
            BuildingNearbyStationFormFieldValuesType
          >;
          stationId: FieldArrayChangeFieldProps<
            "stationId",
            BuildingNearbyStationFormFieldValuesType
          >;
          searchQuery: FieldArrayChangeFieldProps<
            "searchQuery",
            BuildingNearbyStationFormFieldValuesType
          >;
          timeRequired: { name: string; value: number | undefined };
          exitName: { name: string; value: string | undefined };
        };
        onChange: FieldHandlerType;
        onBlur: FieldHandlerType;
        onRemove: MouseEventHandler<HTMLAnchorElement>;
      }[];
      onAppend: MouseEventHandler;
    };
  };
  isFieldShown: {
    managementType: boolean;
    lessorBranch: boolean;
    labels: boolean;
    brand: boolean;
  };
  fieldErrors: {
    [K in Exclude<BuildingFormFieldKeysType, ArrayFieldKeysType>]?: string;
  } & {
    oldNames?: {
      message?: string;
      fields?: {
        [I in number]?: {
          [K in BuildingOldNameFormFieldKeysType]?: string;
        };
      };
    };
    nearbyStations?: {
      message?: string;
      fields?: {
        [I in number]?: {
          [K in BuildingNearbyStationFormFieldKeysType]?: string;
        };
      };
    };
  };
  onSubmit: FormEventHandler<HTMLFormElement>;
  isSubmitting: boolean;
  isLoading: boolean;
  hasErrors: {
    validation: boolean;
    submission: boolean;
  };
  isOwner: boolean;
  prefectureSelectProps: ComponentProps<typeof PrefectureSelect>;
  wardSelectProps: ComponentProps<typeof WardSelect>;
  citySelectProps: ComponentProps<typeof CitySelect>;
  stationsBySearchQuery: Record<
    string,
    ComponentProps<typeof StationSelect>["stations"]
  >;
  stationNameByStationId: Record<number, string>;
  linesByStationId: Record<number, ComponentProps<typeof LineSelect>["lines"]>;
};

export const BuildingFormModal: FC<Props> = ({
  modal,
  fields,
  fieldErrors,
  isFieldShown,
  onSubmit,
  isSubmitting,
  isLoading,
  hasErrors,
  isOwner,
  prefectureSelectProps,
  wardSelectProps,
  citySelectProps,
  stationsBySearchQuery,
  stationNameByStationId,
  linesByStationId,
}) => {
  return (
    <Modal {...modal}>
      <ModalForm
        heading="物件情報"
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        isLoading={isLoading}
        errors={
          hasErrors.validation
            ? ["入力内容に不備があります。"]
            : hasErrors.submission
            ? [
                "保存に失敗しました。通信環境を確認の上、もう一度お試しください。",
              ]
            : undefined
        }
      >
        <div>
          <div css={buildingFormModalSectionStyle}>
            <Heading
              heading="物件画像"
              css={buildingFormModalSectionHeadingStyle}
            />

            <ul>
              <li css={buildingFormModalFieldStyle}>
                <FormField>
                  <div css={uploadImageContainerStyle}>
                    <div css={uploadImageFieldStyle}>
                      <span css={uploadImageFieldLabelStyle}>外観</span>
                      <div css={uploadImagesStyle}>
                        <UploadImage
                          label="アップロード"
                          css={uploadImageStyle}
                          accept=".jpg,.jpeg,.png"
                          displayImageUrl={
                            fields.buildingImageExteriorUrl.value
                          }
                          onLoadImageData={(dataUrl) =>
                            fields.buildingImageExteriorUrl.onChange(dataUrl)
                          }
                          onChangeImageFile={(file) =>
                            fields.buildingImageExteriorFile.onChange(file)
                          }
                          onDeleteImageUrl={() => {
                            fields.buildingImageExteriorUrl.onChange(undefined);
                            fields.buildingImageExteriorFile.onChange(
                              undefined,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div css={uploadImageFieldStyle}>
                      <span css={uploadImageFieldLabelStyle}>地図</span>
                      <div css={uploadImagesStyle}>
                        <UploadImage
                          label="アップロード"
                          css={uploadImageStyle}
                          accept=".jpg,.jpeg,.png"
                          displayImageUrl={
                            fields.buildingImageVicinityMapUrl.value
                          }
                          onLoadImageData={(dataUrl) =>
                            fields.buildingImageVicinityMapUrl.onChange(dataUrl)
                          }
                          onChangeImageFile={(file) =>
                            fields.buildingImageVicinityMapFile.onChange(file)
                          }
                          onDeleteImageUrl={() => {
                            fields.buildingImageVicinityMapUrl.onChange(
                              undefined,
                            );
                            fields.buildingImageVicinityMapFile.onChange(
                              undefined,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <span
                    css={css`
                      line-height: ${LineHeight[140]};
                      font-size: ${text.size[12]};
                      color: ${Color.Neutral.Base.Secondary};
                    `}
                  >
                    ※png, jpgに対応
                  </span>
                </FormField>
              </li>
            </ul>
          </div>

          <div css={buildingFormModalSectionStyle}>
            <Heading
              heading="基本情報"
              css={buildingFormModalSectionHeadingStyle}
            />
            <ul>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="物件名" isRequired>
                  <Inputbox
                    isRHF
                    id="building-name"
                    inputType="text"
                    placeholder="estieビル"
                    testId="building_name_input"
                    {...fields.name}
                  />
                  {fieldErrors.name && (
                    <ErrorMessage>{fieldErrors.name}</ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="旧名称">
                  {fields.oldNames.fields.map((oldName, index) => (
                    <>
                      <div
                        key={oldName.id}
                        css={[
                          grid("1fr 1fr 24px", Spacing[8], "none", "normal"),
                          buildingFormModalOldNameFieldsStyle,
                        ]}
                      >
                        <Inputbox
                          isRHF
                          inputType="text"
                          label="旧名称"
                          placeholder="estieビル"
                          testId="old_name_name"
                          onChange={oldName.onChange}
                          onBlur={oldName.onBlur}
                          {...oldName.items.name}
                        />

                        <Inputbox
                          inputType="month"
                          label="名称変更年月"
                          placeholder="2020年1月"
                          pattern="[0-9]{4}-[0-9]{2}"
                          testId="old_name_renamed"
                          // Firefox では type=month が対応していないため
                          uncontrolled
                          defaultValue={
                            oldName.items.year.value &&
                            oldName.items.month.value
                              ? `${
                                  oldName.items.year.value
                                }-${oldName.items.month.value
                                  .toString()
                                  .padStart(2, "0")}`
                              : undefined
                          }
                          onChange={(newValue) => {
                            if (newValue === "") {
                              oldName.items.year.onChange(undefined);
                              oldName.items.month.onChange(undefined);
                            } else {
                              const [year, month] = newValue.split("-");
                              oldName.items.year.onChange(parseInt(year));
                              oldName.items.month.onChange(parseInt(month));
                            }
                          }}
                        />

                        <a
                          css={buildingFormModalOldNameRemoveButtonStyle}
                          onClick={oldName.onRemove}
                        >
                          <FontAwesomeIcon icon={faMinusCircle} />
                        </a>
                      </div>
                      {fieldErrors.oldNames?.fields?.[index]?.name && (
                        <ErrorMessage>
                          {fieldErrors.oldNames?.fields?.[index]?.name}
                        </ErrorMessage>
                      )}
                      {fieldErrors.oldNames?.fields?.[index]?.year && (
                        <ErrorMessage>
                          {fieldErrors.oldNames?.fields?.[index]?.year}
                        </ErrorMessage>
                      )}
                      {fieldErrors.oldNames?.fields?.[index]?.month && (
                        <ErrorMessage>
                          {fieldErrors.oldNames?.fields?.[index]?.month}
                        </ErrorMessage>
                      )}
                    </>
                  ))}
                  <div>
                    <a
                      onClick={fields.oldNames.onAppend}
                      css={buildingFormModalOldNameAddButtonStyle}
                    >
                      <span css={buildingFormModalOldNameAddButtonIconStyle}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </span>
                      <span>旧名称を追加する</span>
                    </a>
                  </div>
                  {fieldErrors.oldNames?.message && (
                    <ErrorMessage>{fieldErrors.oldNames?.message}</ErrorMessage>
                  )}
                </FormField>
              </li>

              {isFieldShown.brand && (
                <li css={buildingFormModalFieldStyle}>
                  <FormField heading="ブランド">
                    <Selectbox
                      isRHF
                      id="building-building-brand"
                      optionDefaultText="選択してください"
                      {...fields.buildingBrandId}
                    />
                    {fieldErrors.buildingBrandId && (
                      <ErrorMessage>{fieldErrors.buildingBrandId}</ErrorMessage>
                    )}
                  </FormField>

                  {!isOwner && (
                    <div
                      css={buildingFormModalOwnerInformationWarningMessageStyle}
                    >
                      <div>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color={Color.Neutral.Base.Secondary}
                        />
                      </div>
                      <div>
                        運用者権限を持っている企業のブランドが表示されています。了承なしに編集しないてください。
                      </div>
                    </div>
                  )}
                </li>
              )}

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="用途">
                  <Selectbox
                    isRHF
                    id="building-building-use"
                    optionDefaultText="選択してください"
                    {...fields.buildingUse}
                  />
                  {fieldErrors.buildingUse && (
                    <ErrorMessage>{fieldErrors.buildingUse}</ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField
                  heading="住所"
                  data-testid="address_input"
                  isRequired
                >
                  <div
                    css={grid("1fr 1fr", Spacing[8], "auto auto", Spacing[8])}
                  >
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <PrefectureSelect
                        {...prefectureSelectProps}
                        testId="building_prefecture_select"
                      />
                      {fieldErrors.prefecturePidanId && (
                        <ErrorMessage>
                          {fieldErrors.prefecturePidanId}
                        </ErrorMessage>
                      )}
                    </div>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <WardSelect
                        {...wardSelectProps}
                        testId="building_ward_select"
                      />
                      {fieldErrors.wardPidanId && (
                        <ErrorMessage>{fieldErrors.wardPidanId}</ErrorMessage>
                      )}
                    </div>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <CitySelect
                        {...citySelectProps}
                        testId="building_city_select"
                      />
                      {fieldErrors.cityPidanId && (
                        <ErrorMessage>{fieldErrors.cityPidanId}</ErrorMessage>
                      )}
                    </div>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <Inputbox
                        isRHF
                        id="building-block"
                        inputType="text"
                        label="以降の所在地（任意）"
                        placeholder="1-1-1"
                        testId="building_block_input"
                        {...fields.pidanBlock}
                      />
                      {fieldErrors.pidanBlock && (
                        <ErrorMessage>{fieldErrors.pidanBlock}</ErrorMessage>
                      )}
                    </div>
                  </div>
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="緯度経度">
                  <div css={grid("1fr 1fr", Spacing[8], "none", "normal")}>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <Inputbox
                        isRHF
                        label="緯度"
                        placeholder="35.681305"
                        testId="building_latitude_input"
                        {...fields.latitude}
                      />
                      {fieldErrors.latitude && (
                        <ErrorMessage>{fieldErrors.latitude}</ErrorMessage>
                      )}
                    </div>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <Inputbox
                        isRHF
                        label="経度"
                        placeholder="139.767143"
                        testId="building_longitude_input"
                        {...fields.longitude}
                      />
                      {fieldErrors.longitude && (
                        <ErrorMessage>{fieldErrors.longitude}</ErrorMessage>
                      )}
                    </div>
                  </div>
                  <div>
                    <TextLink
                      css={buildingFormModalGoogleMapLinkStyle}
                      href={"https://www.google.co.jp/maps/?hl=ja"}
                      target="_blank"
                    >
                      <span>Googleマップで調べる</span>
                      <span>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      </span>
                    </TextLink>
                  </div>
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="最寄駅" isRequired>
                  {fields.nearbyStations.fields.map((nearbyStation, index) => {
                    const stationId = nearbyStation.items.stationId.value;
                    const lineId = nearbyStation.items.lineId.value;
                    return (
                      <>
                        <div
                          key={nearbyStation.id}
                          css={[
                            grid(
                              "1fr 192px 96px 96px auto",
                              Spacing[8],
                              "none",
                              "normal",
                            ),
                            buildingFormModalNearbyStationStyle,
                          ]}
                        >
                          <StationSelect
                            name="station-name"
                            currentStation={
                              stationId
                                ? {
                                    id: stationId,
                                    name: stationNameByStationId[stationId],
                                  }
                                : undefined
                            }
                            stations={
                              stationsBySearchQuery[
                                nearbyStation.items.searchQuery.value ?? ""
                              ] ?? []
                            }
                            onSelect={(station) => {
                              nearbyStation.items.stationId.onChange(
                                station.id,
                              );
                            }}
                            onDelete={() => {
                              nearbyStation.items.stationId.onChange(undefined);
                            }}
                            searchQuery={
                              nearbyStation.items.searchQuery.value ?? ""
                            }
                            onQueryChange={(newQuery) =>
                              nearbyStation.items.searchQuery.onChange(newQuery)
                            }
                            testId="station_input"
                          />

                          <LineSelect
                            currentStation={
                              nearbyStation.items.stationId.value
                                ? {
                                    id: nearbyStation.items.stationId.value,
                                    name: stationNameByStationId[
                                      nearbyStation.items.stationId.value
                                    ],
                                  }
                                : undefined
                            }
                            currentLine={
                              stationId && lineId
                                ? {
                                    id: lineId,
                                    name:
                                      linesByStationId[stationId]?.find(
                                        (line) => line.id === lineId,
                                      )?.name ?? "",
                                  }
                                : undefined
                            }
                            lines={linesByStationId[stationId] ?? []}
                            onSelect={(line) => {
                              nearbyStation.items.lineId.onChange(line?.id);
                            }}
                          />

                          <Inputbox
                            isRHF
                            id="station-walk-time-input"
                            label="徒歩"
                            placeholder="5"
                            endAdornment="分"
                            testId="station_walk_time_input"
                            onChange={nearbyStation.onChange}
                            onBlur={nearbyStation.onBlur}
                            {...nearbyStation.items.timeRequired}
                          />

                          <Inputbox
                            isRHF
                            inputType="text"
                            label="出口（任意）"
                            placeholder="東口"
                            testId="station_exit_input"
                            onChange={nearbyStation.onChange}
                            onBlur={nearbyStation.onBlur}
                            {...nearbyStation.items.exitName}
                          />
                          {fields.nearbyStations.fields.length > 1 && (
                            <a
                              css={
                                buildingFormModalNearbyStationRemoveButtonStyle
                              }
                              onClick={nearbyStation.onRemove}
                            >
                              <FontAwesomeIcon icon={faMinusCircle} />
                            </a>
                          )}
                        </div>
                        {fieldErrors.nearbyStations?.fields?.[index]
                          ?.exitName && (
                          <ErrorMessage>
                            {
                              fieldErrors.nearbyStations?.fields?.[index]
                                ?.exitName
                            }
                          </ErrorMessage>
                        )}
                        {fieldErrors.nearbyStations?.fields?.[index]
                          ?.lineId && (
                          <ErrorMessage>
                            {
                              fieldErrors.nearbyStations?.fields?.[index]
                                ?.lineId
                            }
                          </ErrorMessage>
                        )}
                        {fieldErrors.nearbyStations?.fields?.[index]
                          ?.stationId && (
                          <ErrorMessage>
                            {
                              fieldErrors.nearbyStations?.fields?.[index]
                                ?.stationId
                            }
                          </ErrorMessage>
                        )}
                        {fieldErrors.nearbyStations?.fields?.[index]
                          ?.timeRequired && (
                          <ErrorMessage>
                            {
                              fieldErrors.nearbyStations?.fields?.[index]
                                ?.timeRequired
                            }
                          </ErrorMessage>
                        )}
                      </>
                    );
                  })}
                  <div>
                    <a
                      css={buildingFormModalNearbyStationAppendButtonStyle}
                      onClick={fields.nearbyStations.onAppend}
                    >
                      <span
                        css={
                          buildingFormModalNearbyStationAppendButtonIconStyle
                        }
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </span>
                      <span>最寄駅を追加する</span>
                    </a>
                  </div>
                  {fieldErrors.nearbyStations?.message && (
                    <ErrorMessage>
                      {fieldErrors.nearbyStations?.message}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>
            </ul>
          </div>

          <div css={buildingFormModalSectionStyle}>
            <Heading
              heading="管理"
              css={buildingFormModalSectionHeadingStyle}
            />

            <ul>
              {isFieldShown.managementType && (
                <li css={buildingFormModalFieldStyle}>
                  <FormField heading="管理区分">
                    <Selectbox
                      isRHF
                      id="building-building-management-type"
                      testId="building_management_type"
                      optionDefaultText="選択してください"
                      {...fields.buildingManagementTypeId}
                    />

                    {!isOwner && (
                      <div
                        css={
                          buildingFormModalOwnerInformationWarningMessageStyle
                        }
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            color={Color.Neutral.Base.Secondary}
                          />
                        </div>
                        <div>
                          運用者権限を持っている企業の管理区分が表示されています。了承なしに編集しないてください。
                        </div>
                      </div>
                    )}

                    {fieldErrors.buildingManagementTypeId && (
                      <ErrorMessage>
                        {fieldErrors.buildingManagementTypeId}
                      </ErrorMessage>
                    )}
                  </FormField>
                </li>
              )}

              {isFieldShown.lessorBranch && (
                <li css={buildingFormModalFieldStyle}>
                  <FormField heading="担当支店・部署">
                    <Selectbox
                      isRHF
                      id="building-lessor-branches"
                      optionDefaultText="選択してください"
                      {...fields.lessorBranchId}
                    />

                    {!isOwner && (
                      <div
                        css={
                          buildingFormModalOwnerInformationWarningMessageStyle
                        }
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            color={Color.Neutral.Base.Secondary}
                          />
                        </div>
                        <div>
                          運用者権限を持っている企業の担当支店・部署が表示されています。了承なしに編集しないてください。
                        </div>
                      </div>
                    )}

                    {fieldErrors.lessorBranchId && (
                      <ErrorMessage>{fieldErrors.lessorBranchId}</ErrorMessage>
                    )}
                  </FormField>
                </li>
              )}

              {isFieldShown.labels && (
                <li css={buildingFormModalFieldStyle}>
                  <FormField heading="ラベル">
                    <div>
                      <SelectboxMultiple
                        defaultText="選択してください"
                        checkedTexts={fields.buildingTagIds.options
                          .filter((option) => option.isSelected)
                          .map((option) => option.label)}
                        checkboxName="building_tag_options"
                        checkboxes={fields.buildingTagIds.options}
                      />
                    </div>

                    {!isOwner && (
                      <div
                        css={
                          buildingFormModalOwnerInformationWarningMessageStyle
                        }
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            color={Color.Neutral.Base.Secondary}
                          />
                        </div>
                        <div>
                          運用者権限を持っている企業のラベルが表示されています。了承なしに編集しないてください。
                        </div>
                      </div>
                    )}

                    {fieldErrors.buildingTagIds && (
                      <ErrorMessage>{fieldErrors.buildingTagIds}</ErrorMessage>
                    )}
                  </FormField>
                </li>
              )}
            </ul>
          </div>

          <div css={buildingFormModalSectionStyle}>
            <Heading
              heading="スペック"
              css={buildingFormModalSectionHeadingStyle}
            />

            <ul>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="竣工年月" isRequired>
                  <Inputbox
                    id="building-completed"
                    name="building-completed"
                    inputType="month"
                    placeholder="2020-01"
                    pattern="[0-9]{4}-[0-9]{2}"
                    testId="building_completed_input"
                    // Firefox では type=month が対応していないため
                    uncontrolled
                    defaultValue={
                      fields.completedYear.value && fields.completedMonth.value
                        ? `${
                            fields.completedYear.value
                          }-${fields.completedMonth.value
                            .toString()
                            .padStart(2, "0")}`
                        : undefined
                    }
                    onChange={(newValue) => {
                      if (newValue === "") {
                        fields.completedYear.onChange(undefined);
                        fields.completedMonth.onChange(undefined);
                      } else {
                        const [year, month] = newValue.split("-");
                        fields.completedYear.onChange(parseInt(year));
                        fields.completedMonth.onChange(parseInt(month));
                      }
                    }}
                    onBlur={() => {
                      fields.completedYear.onBlur();
                      fields.completedMonth.onBlur();
                    }}
                  />

                  {fieldErrors.completedYear && (
                    <ErrorMessage>{fieldErrors.completedYear}</ErrorMessage>
                  )}
                  {fieldErrors.completedMonth && (
                    <ErrorMessage>{fieldErrors.completedMonth}</ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="リニューアル年月">
                  <Inputbox
                    id="building-renewal"
                    name="building-renewal"
                    inputType="month"
                    placeholder="2020年1月"
                    pattern="[0-9]{4}-[0-9]{2}"
                    // Firefox では type=month が対応していないため
                    uncontrolled
                    defaultValue={
                      fields.renewalYear.value && fields.renewalMonth.value
                        ? `${
                            fields.renewalYear.value
                          }-${fields.renewalMonth.value
                            .toString()
                            .padStart(2, "0")}`
                        : undefined
                    }
                    onChange={(newValue) => {
                      if (newValue === "") {
                        fields.renewalYear.onChange(undefined);
                        fields.renewalMonth.onChange(undefined);
                      } else {
                        const [year, month] = newValue.split("-");
                        fields.renewalYear.onChange(parseInt(year));
                        fields.renewalMonth.onChange(parseInt(month));
                      }
                    }}
                    onBlur={() => {
                      fields.renewalYear.onBlur();
                      fields.renewalMonth.onBlur();
                    }}
                  />

                  {fieldErrors.renewalYear && (
                    <ErrorMessage>{fieldErrors.renewalYear}</ErrorMessage>
                  )}
                  {fieldErrors.renewalMonth && (
                    <ErrorMessage>{fieldErrors.renewalMonth}</ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="階建" isRequired>
                  <div css={grid("1fr 1fr", Spacing[8], "none", "normal")}>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <Inputbox
                        isRHF
                        id="building-ground-floor-input"
                        label="地上階数"
                        placeholder="10"
                        endAdornment="F"
                        testId="building_ground_floor_input"
                        {...fields.groundfloor}
                      />
                    </div>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <Inputbox
                        isRHF
                        id="building-basement-floor-input"
                        label="地下階数"
                        placeholder="3"
                        endAdornment="F"
                        testId="building_basement_input"
                        {...fields.basement}
                      />
                    </div>
                  </div>
                  {fieldErrors.groundfloor && (
                    <ErrorMessage>{fieldErrors.groundfloor}</ErrorMessage>
                  )}
                  {fieldErrors.basement && (
                    <ErrorMessage>{fieldErrors.basement}</ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="延床面積">
                  <Inputbox
                    isRHF
                    placeholder="2300.34"
                    endAdornment="坪"
                    testId="building_gross_floor_area_input"
                    {...fields.grossFloorArea}
                  />
                  {fieldErrors.grossFloorArea && (
                    <ErrorMessage>{fieldErrors.grossFloorArea}</ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="総貸付有効面積">
                  <Inputbox
                    isRHF
                    placeholder="1800.22"
                    endAdornment="坪"
                    {...fields.netLeasableAreaTotal}
                  />
                  {fieldErrors.netLeasableAreaTotal && (
                    <ErrorMessage>
                      {fieldErrors.netLeasableAreaTotal}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="耐震基準" isRequired>
                  <Selectbox
                    isRHF
                    id="building-earthquake-resistant-structure"
                    testId="building-earthquake-resistant-structure"
                    optionDefaultText="選択してください"
                    {...fields.earthquakeResistantStructure}
                  />
                  {fieldErrors.earthquakeResistantStructure && (
                    <ErrorMessage>
                      {fieldErrors.earthquakeResistantStructure}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="構造">
                  <Selectbox
                    isRHF
                    id="building-structure-type"
                    testId="building-structure-type"
                    optionDefaultText="選択してください"
                    {...fields.structureType}
                  />
                  {fieldErrors.structureType && (
                    <ErrorMessage>{fieldErrors.structureType}</ErrorMessage>
                  )}
                  {fields.structureType.value ===
                    GraphQLStructureType.Other && (
                    <Inputbox
                      isRHF
                      id="building-structure-text"
                      testId="building-structure-text"
                      inputType="text"
                      placeholder="鉄骨造（一部RC造）"
                      {...fields.structureText}
                    />
                  )}
                  {fieldErrors.structureText && (
                    <ErrorMessage>{fieldErrors.structureText}</ErrorMessage>
                  )}
                </FormField>
              </li>
            </ul>
          </div>

          <div css={buildingFormModalSectionStyle}>
            <Heading
              heading="基準階仕様"
              css={buildingFormModalSectionHeadingStyle}
            />

            <ul>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="基準階面積" isRequired>
                  <Inputbox
                    isRHF
                    placeholder="150.23"
                    endAdornment="坪"
                    testId="building_standard_floor_area_input"
                    {...fields.standardFloorArea}
                  />
                  {fieldErrors.standardFloorArea && (
                    <ErrorMessage>{fieldErrors.standardFloorArea}</ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="基準階天井高">
                  <Inputbox
                    isRHF
                    placeholder="2700"
                    endAdornment="mm"
                    {...fields.standardUnitFloorHeight}
                  />
                  {fieldErrors.standardUnitFloorHeight && (
                    <ErrorMessage>
                      {fieldErrors.standardUnitFloorHeight}
                    </ErrorMessage>
                  )}
                  <Inputbox
                    isRHF
                    inputType="text"
                    label="例外の高さ"
                    placeholder="梁下2,200mm、一部2,100mmの箇所あり"
                    {...fields.standardUnitFloorHeightSideNote}
                  />
                  {fieldErrors.standardUnitFloorHeightSideNote && (
                    <ErrorMessage>
                      {fieldErrors.standardUnitFloorHeightSideNote}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="基準階床荷重">
                  <Inputbox
                    isRHF
                    placeholder="500"
                    endAdornment="kg/㎡"
                    testId="unit_floor_loading"
                    {...fields.standardUnitFloorLoading}
                  />
                  {fieldErrors.standardUnitFloorLoading && (
                    <ErrorMessage>
                      {fieldErrors.standardUnitFloorLoading}
                    </ErrorMessage>
                  )}
                  <Inputbox
                    isRHF
                    inputType="text"
                    label="床荷重の補足"
                    placeholder="一部1,000kg/㎡"
                    testId="unit_floor_loading_side_note"
                    {...fields.standardUnitFloorLoadingSideNote}
                  />
                  {fieldErrors.standardUnitFloorLoadingSideNote && (
                    <ErrorMessage>
                      {fieldErrors.standardUnitFloorLoadingSideNote}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="基準階床仕様">
                  <Selectbox
                    isRHF
                    id="unit_floor_material_type"
                    testId="unit_floor_material_type"
                    optionDefaultText="選択してください"
                    {...fields.standardUnitFloorMaterial}
                  />
                  {fieldErrors.standardUnitFloorHeightSideNote && (
                    <ErrorMessage>
                      {fieldErrors.standardUnitFloorMaterial}
                    </ErrorMessage>
                  )}
                  {fields.standardUnitFloorMaterial.value ===
                    GraphQLFloorMaterialType.Others && (
                    <Inputbox
                      isRHF
                      testId="unit_floor_material_text"
                      label={
                        "床仕様（その他を選択した場合は必ず入力してください）"
                      }
                      placeholder="スケルトン"
                      {...fields.standardUnitFloorMaterialSideNote}
                    />
                  )}
                  {fieldErrors.standardUnitFloorMaterialSideNote && (
                    <ErrorMessage>
                      {fieldErrors.standardUnitFloorMaterialSideNote}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>

              <li css={buildingFormModalFieldStyle}>
                <FormField heading="基準階OAフロア">
                  <Selectbox
                    isRHF
                    id="unit_oa_floor_type"
                    testId="unit_oa_floor_type"
                    optionDefaultText="選択してください"
                    {...fields.standardUnitOaFloorType}
                  />
                  {fieldErrors.standardUnitOaFloorType && (
                    <ErrorMessage>
                      {fieldErrors.standardUnitOaFloorType}
                    </ErrorMessage>
                  )}
                  {(fields.standardUnitOaFloorType.value ===
                    GraphQLOaFloorType.Equipped ||
                    fields.standardUnitOaFloorType.value ===
                      GraphQLOaFloorType.PartiallyEquipped) && (
                    <Inputbox
                      isRHF
                      testId="unit_oa_floor_height"
                      label={"高さ"}
                      placeholder="100"
                      endAdornment="mm"
                      {...fields.standardUnitOaFloorHeight}
                    />
                  )}
                  {fieldErrors.standardUnitOaFloorHeight && (
                    <ErrorMessage>
                      {fieldErrors.standardUnitOaFloorHeight}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>
            </ul>
          </div>

          <div css={buildingFormModalSectionStyle}>
            <Heading
              heading="設備"
              css={buildingFormModalSectionHeadingStyle}
            />

            <ul>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="エレベーター">
                  <div css={grid("1fr 1fr", Spacing[8], "none", "normal")}>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <Inputbox
                        isRHF
                        label="台数"
                        placeholder="2"
                        endAdornment="台"
                        testId="building_elevators_num_input"
                        {...fields.elevatorNum}
                      />
                      {fieldErrors.elevatorNum && (
                        <ErrorMessage>{fieldErrors.elevatorNum}</ErrorMessage>
                      )}
                    </div>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <Inputbox
                        isRHF
                        label="定員"
                        placeholder="6"
                        endAdornment="人"
                        testId="building_elevators_capacity_input"
                        {...fields.elevatorCapacity}
                      />
                      {fieldErrors.elevatorCapacity && (
                        <ErrorMessage>
                          {fieldErrors.elevatorCapacity}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                  <Selectbox
                    isRHF
                    id="building-elevators-access-control"
                    testId="building_elevators_access_control"
                    optionDefaultText="選択してください"
                    label="不停止制御装置"
                    {...fields.elevatorAccessControl}
                  />
                  {fieldErrors.elevatorAccessControl && (
                    <ErrorMessage>
                      {fieldErrors.elevatorAccessControl}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="駐車場">
                  <div css={grid("1fr 1fr", Spacing[8], "none", "normal")}>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      <Inputbox
                        isRHF
                        label="総台数"
                        placeholder="20"
                        endAdornment="台"
                        testId="building_parking_lot_total_input"
                        {...fields.parkingLotTotal}
                      />
                      {fieldErrors.parkingLotTotal && (
                        <ErrorMessage>
                          {fieldErrors.parkingLotTotal}
                        </ErrorMessage>
                      )}
                    </div>
                    <div css={buildingFormModalFieldInputWithErrorStyle}>
                      {" "}
                      <Inputbox
                        isRHF
                        label="空き台数"
                        placeholder="5"
                        endAdornment="台"
                        testId="building_parking_lot_vacant_input"
                        {...fields.parkingLotVacant}
                      />
                      {fieldErrors.parkingLotVacant && (
                        <ErrorMessage>
                          {fieldErrors.parkingLotVacant}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                </FormField>
              </li>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="空調">
                  <Selectbox
                    isRHF
                    id="building-air-conditioner-type"
                    testId="building-air-conditioner-type"
                    optionDefaultText="選択してください"
                    {...fields.airConditionType}
                  />
                  {fieldErrors.airConditionType && (
                    <ErrorMessage>{fieldErrors.airConditionType}</ErrorMessage>
                  )}
                  {fields.airConditionType.value ===
                    GraphQLAirConditionerType.Other && (
                    <Inputbox
                      isRHF
                      id="building-air-conditioner-text"
                      testId="building-air-conditioner-text"
                      inputType="text"
                      placeholder="セントラル（個別発停可能）"
                      {...fields.airConditionText}
                    />
                  )}
                  {fieldErrors.airConditionText && (
                    <ErrorMessage>{fieldErrors.airConditionText}</ErrorMessage>
                  )}
                </FormField>
              </li>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="電源容量">
                  <div css={buildingFormModalFieldInputWithErrorStyle}>
                    <Inputbox
                      isRHF
                      placeholder="30"
                      endAdornment="VA/㎡"
                      testId="building_power_capacity_input"
                      {...fields.powerCapacity}
                    />
                    {fieldErrors.powerCapacity && (
                      <ErrorMessage>{fieldErrors.powerCapacity}</ErrorMessage>
                    )}
                  </div>
                  <div css={buildingFormModalFieldInputWithErrorStyle}>
                    <Inputbox
                      isRHF
                      inputType="text"
                      label="電源容量の補足"
                      placeholder="50VA/㎡増強可"
                      testId="building_power_capacity_side_note_input"
                      {...fields.powerCapacitySideNote}
                    />
                    {fieldErrors.powerCapacitySideNote && (
                      <ErrorMessage>
                        {fieldErrors.powerCapacitySideNote}
                      </ErrorMessage>
                    )}
                  </div>
                </FormField>
              </li>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="非常用発電機">
                  <Inputbox
                    isRHF
                    id="building-emergency-generator"
                    inputType="text"
                    placeholder="72時間"
                    {...fields.emergencyGenerator}
                  />
                  {fieldErrors.emergencyGenerator && (
                    <ErrorMessage>
                      {fieldErrors.emergencyGenerator}
                    </ErrorMessage>
                  )}
                </FormField>
              </li>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="管理人">
                  <Selectbox
                    isRHF
                    id="building-janitor"
                    testId="building-janitor"
                    optionDefaultText="選択してください"
                    {...fields.janitor}
                  />
                  {fieldErrors.janitor && (
                    <ErrorMessage>{fieldErrors.janitor}</ErrorMessage>
                  )}
                </FormField>
              </li>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="オートロック">
                  <Selectbox
                    isRHF
                    id="building-self-locking"
                    testId="building_self_locking"
                    optionDefaultText="選択してください"
                    {...fields.selfLocking}
                  />
                  {fieldErrors.selfLocking && (
                    <ErrorMessage>{fieldErrors.selfLocking}</ErrorMessage>
                  )}
                </FormField>
              </li>
              <li css={buildingFormModalFieldStyle}>
                <FormField heading="警備">
                  <Inputbox
                    isRHF
                    id="building-security"
                    inputType="text"
                    placeholder="機械警備"
                    {...fields.security}
                  />
                  {fieldErrors.security && (
                    <ErrorMessage>{fieldErrors.security}</ErrorMessage>
                  )}
                </FormField>
              </li>
            </ul>
          </div>

          <div css={buildingFormModalSectionStyle}>
            <Heading
              heading="設備詳細"
              css={buildingFormModalSectionHeadingStyle}
            />
            <ul>
              <li css={buildingFormModalFieldStyle}>
                <FormField>
                  <div css={buildingFormModalFacilityDetailStyle}>
                    <Checkbox
                      isChecked={
                        !!fields.facilityDetailDirectAccessToStation.value
                      }
                      onToggle={
                        fields.facilityDetailDirectAccessToStation.onToggle
                      }
                      name={fields.facilityDetailDirectAccessToStation.name}
                      ref={fields.facilityDetailDirectAccessToStation.ref}
                    >
                      駅直結
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailAlongMainStreet.value}
                      onToggle={fields.facilityDetailAlongMainStreet.onToggle}
                      name={fields.facilityDetailAlongMainStreet.name}
                      ref={fields.facilityDetailAlongMainStreet.ref}
                    >
                      大通り沿い
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailMuchSunshine.value}
                      onToggle={fields.facilityDetailMuchSunshine.onToggle}
                      name={fields.facilityDetailMuchSunshine.name}
                      ref={fields.facilityDetailMuchSunshine.ref}
                    >
                      日当たり良好
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailTwentyFourSeven.value}
                      onToggle={fields.facilityDetailTwentyFourSeven.onToggle}
                      name={fields.facilityDetailTwentyFourSeven.name}
                      ref={fields.facilityDetailTwentyFourSeven.ref}
                    >
                      24時間使用可能
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailSeparateWc.value}
                      onToggle={fields.facilityDetailSeparateWc.onToggle}
                      name={fields.facilityDetailSeparateWc.name}
                      ref={fields.facilityDetailSeparateWc.ref}
                    >
                      男女トイレ別
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailWashlet.value}
                      onToggle={fields.facilityDetailWashlet.onToggle}
                      name={fields.facilityDetailWashlet.name}
                      ref={fields.facilityDetailWashlet.ref}
                    >
                      ウォシュレット
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailOpticalFiber.value}
                      onToggle={fields.facilityDetailOpticalFiber.onToggle}
                      name={fields.facilityDetailOpticalFiber.name}
                      ref={fields.facilityDetailOpticalFiber.ref}
                    >
                      光ファイバー
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailEmergencyPower.value}
                      onToggle={fields.facilityDetailEmergencyPower.onToggle}
                      name={fields.facilityDetailEmergencyPower.name}
                      ref={fields.facilityDetailEmergencyPower.ref}
                    >
                      非常用発電機
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailCafeteria.value}
                      onToggle={fields.facilityDetailCafeteria.onToggle}
                      name={fields.facilityDetailCafeteria.name}
                      ref={fields.facilityDetailCafeteria.ref}
                    >
                      食堂
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailNursery.value}
                      onToggle={fields.facilityDetailNursery.onToggle}
                      name={fields.facilityDetailNursery.name}
                      ref={fields.facilityDetailNursery.ref}
                    >
                      託児所
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailRefreshingSpace.value}
                      onToggle={fields.facilityDetailRefreshingSpace.onToggle}
                      name={fields.facilityDetailRefreshingSpace.name}
                      ref={fields.facilityDetailRefreshingSpace.ref}
                    >
                      リフレッシュスペース
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailSmokingRoom.value}
                      onToggle={fields.facilityDetailSmokingRoom.onToggle}
                      name={fields.facilityDetailSmokingRoom.name}
                      ref={fields.facilityDetailSmokingRoom.ref}
                    >
                      喫煙所
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailSoho.value}
                      onToggle={fields.facilityDetailSoho.onToggle}
                      name={fields.facilityDetailSoho.name}
                      ref={fields.facilityDetailSoho.ref}
                    >
                      SOHO
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailHighGrade.value}
                      onToggle={fields.facilityDetailHighGrade.onToggle}
                      name={fields.facilityDetailHighGrade.name}
                      ref={fields.facilityDetailHighGrade.ref}
                    >
                      ハイグレード
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailEco.value}
                      onToggle={fields.facilityDetailEco.onToggle}
                      name={fields.facilityDetailEco.name}
                      ref={fields.facilityDetailEco.ref}
                    >
                      省エネ・環境配慮型ビル
                    </Checkbox>
                    <Checkbox
                      isChecked={!!fields.facilityDetailConcrete.value}
                      onToggle={fields.facilityDetailConcrete.onToggle}
                      name={fields.facilityDetailConcrete.name}
                      ref={fields.facilityDetailConcrete.ref}
                    >
                      コンクリート打ち放し
                    </Checkbox>
                  </div>
                </FormField>
              </li>
            </ul>
          </div>

          <div css={buildingFormModalSectionStyle}>
            <Heading
              heading="備考"
              css={buildingFormModalSectionHeadingStyle}
            />

            <ul>
              <li css={buildingFormModalFieldStyle}>
                <FormField>
                  <div css={buildingFormModalFieldInputWithErrorStyle}>
                    <Textarea
                      isRHF
                      placeholder="駅直結物件で交通利便性が高い"
                      {...fields.explanation}
                    />
                    {fieldErrors.explanation && (
                      <ErrorMessage>{fieldErrors.explanation}</ErrorMessage>
                    )}
                  </div>
                </FormField>
              </li>
            </ul>
          </div>

          <div css={buildingFormModalSectionStyle}>
            <div
              css={[
                buildingFormModalSectionHeadingStyle,
                buildingFormModalSectionHeadingCenterStyle,
              ]}
            >
              <Heading heading="非公開メモ" />
              <TooltipDisplay
                css={buildingFormModalSharedLessorTooltipStyle}
                spacing={Spacing[4]}
                horizontalPosition="anchor-left"
                verticalPosition="bottom"
              >
                <TagLabel
                  size="Small"
                  color={Color.Data.Gray.Primary}
                  isOutlined
                >
                  非公開 <FontAwesomeIcon icon={faInfoCircle} />
                </TagLabel>
                <Tooltip>仲介事業者に非公開の項目です</Tooltip>
              </TooltipDisplay>
            </div>
          </div>

          <ul>
            <li css={buildingFormModalFieldStyle}>
              <FormField>
                <div css={buildingFormModalFieldInputWithErrorStyle}>
                  <Textarea
                    isRHF
                    id="building-internal-explanation"
                    {...fields.internalExplanation}
                  />
                  {fieldErrors.internalExplanation && (
                    <ErrorMessage>
                      {fieldErrors.internalExplanation}
                    </ErrorMessage>
                  )}
                </div>
              </FormField>
            </li>
          </ul>
        </div>
      </ModalForm>
    </Modal>
  );
};

const buildingFormModalSectionStyle = css`
  margin-top: ${Spacing[32]};
  &:first-of-type {
    margin-top: 0;
  }
`;

const buildingFormModalSectionHeadingStyle = css`
  margin-bottom: ${Spacing[16]};
`;

const buildingFormModalFieldStyle = css`
  &:not(:last-child) {
    padding-bottom: ${Spacing[16]};
    margin-bottom: ${Spacing[16]};
    border-bottom: 1px solid ${Color.Neutral.Light.Secondary};
  }
`;

const uploadImageContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${Spacing[8]};
  padding: ${Spacing[4]} 0;
`;

const uploadImageFieldStyle = css`
  background: ${Color.Neutral.Pale.Secondary};
  border: solid 1px ${Color.Neutral.Light.Secondary};
  border-radius: ${RadiusSize[2]};
  padding: ${Spacing[8]};
`;

const uploadImageFieldLabelStyle = css`
  line-height: ${LineHeight[140]};
  font-size: ${text.size[12]};
  font-weight: bold;
  color: ${Color.Neutral.Base.Secondary};
`;

const uploadImagesStyle = css`
  display: flex;
  align-items: center;
  gap: ${Spacing[8]};
  margin-top: ${Spacing[8]};
`;

const uploadImageStyle = css`
  width: 128px;
  height: 128px;
`;

const buildingFormModalOldNameFieldsStyle = css`
  align-items: center;
`;

const buildingFormModalOldNameAddButtonStyle = css`
  display: inline-flex;
  align-items: center;
  font-size: ${text.size[12]};
  color: ${Color.Primary.Base};
  line-height: ${LineHeight[100]};
  &:hover {
    text-decoration: underline;
  }
`;

const buildingFormModalOldNameAddButtonIconStyle = css`
  font-size: ${text.size[12]};
  margin-right: ${Spacing[4]};
`;

const buildingFormModalOldNameRemoveButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: ${text.size[20]};
  color: ${Color.Neutral.Base.Secondary};
`;

const buildingFormModalOwnerInformationWarningMessageStyle = css`
  margin-top: ${Spacing[8]};
  font-size: ${text.size[12]};
  display: flex;
  gap: ${Spacing[4]};
  align-items: baseline;
`;

const buildingFormModalFieldInputWithErrorStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${Spacing[8]};
`;

const buildingFormModalGoogleMapLinkStyle = css`
  display: inline-flex;
  align-items: center;
  font-size: ${text.size[12]};
  color: ${Color.Primary.Base};
  line-height: ${LineHeight[100]};
  gap: ${Spacing[2]};
  &:hover {
    text-decoration: underline;
  }
`;

const buildingFormModalNearbyStationRemoveButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: ${text.size[20]};
  color: ${Color.Neutral.Base.Secondary};
`;

const buildingFormModalNearbyStationAppendButtonStyle = css`
  display: inline-flex;
  align-items: center;
  font-size: ${text.size[12]};
  color: ${Color.Primary.Base};
  line-height: ${LineHeight[100]};
  &:hover {
    text-decoration: underline;
  }
`;

const buildingFormModalNearbyStationAppendButtonIconStyle = css`
  font-size: ${text.size[12]};
  margin-right: ${Spacing[4]};
`;

const buildingFormModalSectionHeadingCenterStyle = css`
  display: flex;
  align-items: center;
`;

const buildingFormModalSharedLessorTooltipStyle = css`
  margin-left: ${Spacing[8]};
`;

const buildingFormModalNearbyStationStyle = css`
  align-items: center;
`;

const buildingFormModalFacilityDetailStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${Spacing[8]};
  align-items: center;
`;
