import Autocomplete from "../../uiParts/dataEntry/Autocomplete";

type Station = {
  id: number;
  name: string;
};

type Props = {
  name: string;
  currentStation?: Station;
  stations: Station[];
  onSelect: (station: Station) => void;
  onDelete: () => void;
  searchQuery: string;
  onQueryChange: (newQuery: string) => void;
  testId: string;
};

export const StationSelect: React.FC<Props> = ({
  name,
  currentStation,
  stations,
  onSelect,
  onDelete,
  searchQuery,
  onQueryChange,
  testId,
}) => (
  <Autocomplete
    name={name}
    label="最寄駅"
    placeholder="東京駅"
    value={currentStation ? currentStation.name : searchQuery}
    onChange={(value) => onQueryChange(value)}
    menuItems={stations.map((station) => ({
      label: station.name,
      onClick: () => onSelect(station),
    }))}
    onDelete={onDelete}
    isSelected={Boolean(currentStation)}
    testId={testId}
  />
);
