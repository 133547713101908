import { useState } from "react";
import { GraphQLCity } from "../../../../graphql";
import Autocomplete from "../../../uiParts/dataEntry/Autocomplete";

type Props = {
  currentCity?: string;
  cities: Pick<GraphQLCity, "id" | "name">[];
  onSelect: (city: Pick<GraphQLCity, "id" | "name">) => void;
  onDelete: () => void;
  isRequired?: boolean;
  testId?: string;
};

const CitySelect: React.FC<Props> = ({
  currentCity,
  cities,
  onSelect,
  onDelete,
  isRequired,
  testId,
}) => {
  const { searchedCities, citySearchText, setSearchText } =
    useCitySearch(cities);

  return (
    <>
      <Autocomplete
        name="city"
        label="町名"
        isRequired={isRequired}
        placeholder="大手町"
        value={currentCity ? currentCity : citySearchText}
        onChange={(value) => setSearchText(value)}
        menuItems={searchedCities.map((city) => {
          return {
            label: city.name,
            onClick: () => onSelect(city),
          };
        })}
        onDelete={() => {
          setSearchText("");
          onDelete();
        }}
        isSelected={Boolean(currentCity)}
        testId={testId}
      />
    </>
  );
};

const useCitySearch = (cities: Pick<GraphQLCity, "id" | "name">[]) => {
  const [citySearchText, setSearchText] = useState<string>("");
  const searchedCities = cities.filter((city) =>
    city.name.includes(citySearchText),
  );
  return { searchedCities, citySearchText, setSearchText };
};

export default CitySelect;
