import { ComponentProps, useMemo } from "react";
import { FieldErrors } from "react-hook-form";
import { BuildingFormModal } from "../../../../../components/modals/BuildingFormModal";
import { BuildingFormFieldValuesType } from "../../../../../validations/schemas/buildingForm/types";

export const useBuildingFieldErrors = (
  errors: FieldErrors<BuildingFormFieldValuesType>,
): {
  fieldErrors: ComponentProps<typeof BuildingFormModal>["fieldErrors"];
  hasValidationError: boolean;
} => {
  const hasValidationError = useMemo(() => {
    return Object.values(errors).some((error) => !!error);
  }, [errors]);

  const fieldErrors = {
    oldNames: errors.oldNames
      ? {
          message: errors.oldNames.message,
          fields: errors.oldNames?.map?.((oldName) => ({
            name: oldName?.name?.message,
            year: oldName?.year?.message,
            month: oldName?.month?.message,
          })),
        }
      : undefined,
    nearbyStations: errors.nearbyStations
      ? {
          message: errors.nearbyStations.message,
          fields: errors.nearbyStations?.map?.((nearbyStation) => ({
            exitName: nearbyStation?.exitName?.message,
            lineId: nearbyStation?.lineId?.message,
            stationId: nearbyStation?.stationId?.message,
            timeRequired: nearbyStation?.timeRequired?.message,
          })),
        }
      : undefined,

    name: errors.name?.message,
    latitude: errors.latitude?.message,
    longitude: errors.longitude?.message,
    groundfloor: errors.groundfloor?.message,
    basement: errors.basement?.message,
    structureText: errors.structureText?.message,
    grossFloorArea: errors.grossFloorArea?.message,
    netLeasableAreaTotal: errors.netLeasableAreaTotal?.message,
    security: errors.security?.message,
    elevatorNum: errors.elevatorNum?.message,
    elevatorCapacity: errors.elevatorCapacity?.message,
    parkingLotTotal: errors.parkingLotTotal?.message,
    parkingLotVacant: errors.parkingLotVacant?.message,
    powerCapacity: errors.powerCapacity?.message,
    powerCapacitySideNote: errors.powerCapacitySideNote?.message,
    airConditionText: errors.airConditionText?.message,
    emergencyGenerator: errors.emergencyGenerator?.message,
    standardFloorArea: errors.standardFloorArea?.message,
    standardUnitFloorHeight: errors.standardUnitFloorHeight?.message,
    standardUnitFloorHeightSideNote:
      errors.standardUnitFloorHeightSideNote?.message,
    standardUnitFloorLoading: errors.standardUnitFloorLoading?.message,
    standardUnitFloorLoadingSideNote:
      errors.standardUnitFloorLoadingSideNote?.message,
    standardUnitFloorMaterialSideNote:
      errors.standardUnitFloorMaterialSideNote?.message,
    standardUnitOaFloorHeight: errors.standardUnitOaFloorHeight?.message,
    explanation: errors.explanation?.message,
    internalExplanation: errors.internalExplanation?.message,
    structureType: errors.structureType?.message,
    buildingUse: errors.buildingUse?.message,
    earthquakeResistantStructure: errors.earthquakeResistantStructure?.message,
    janitor: errors.janitor?.message,
    selfLocking: errors.selfLocking?.message,
    airConditionType: errors.airConditionType?.message,
    elevatorAccessControl: errors.elevatorAccessControl?.message,
    standardUnitFloorMaterial: errors.standardUnitFloorMaterial?.message,
    standardUnitOaFloorType: errors.standardUnitOaFloorType?.message,
    buildingBrandId: errors.buildingBrandId?.message,
    buildingManagementTypeId: errors.buildingManagementTypeId?.message,
    lessorBranchId: errors.lessorBranchId?.message,
    buildingTagIds: errors.buildingTagIds?.message,
    completedYear: errors.completedYear?.message,
    completedMonth: errors.completedMonth?.message,
    renewalYear: errors.renewalYear?.message,
    renewalMonth: errors.renewalMonth?.message,
  };

  return { hasValidationError, fieldErrors };
};
