import { ComponentProps } from "react";
import { useQuery } from "urql";
import { BuildingFormModal } from "../../../../../components/modals/BuildingFormModal";
import { StationsByIdsDocument } from "../../../../../graphql";

export const useBuildingStations = (
  stationIds: number[],
): Pick<
  ComponentProps<typeof BuildingFormModal>,
  "linesByStationId" | "stationNameByStationId"
> => {
  const [{ data: stationByIds }] = useQuery({
    query: StationsByIdsDocument,
    variables: {
      ids: stationIds,
    },
  });

  const linesByStationId = Object.fromEntries(
    stationByIds?.stationsByIds.map((station) => [station.id, station.lines]) ??
      [],
  );
  const stationNameByStationId = Object.fromEntries(
    stationByIds?.stationsByIds.map((station) => [station.id, station.name]) ??
      [],
  );

  return { linesByStationId, stationNameByStationId };
};
