import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps, FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { BuildingFormModal } from "../../../components/modals/BuildingFormModal";
import { Modal } from "../../../contexts/Modal";
import { useModalContext } from "../../../contexts/Modal/context";
import { useToast } from "../../../hooks/context/toast";
import { buildingSchema } from "../../../validations/schemas/buildingForm";
import { BuildingFormFieldValuesType } from "../../../validations/schemas/buildingForm/types";
import { useBuildingFieldErrors } from "./hooks/useBuildingFieldErrors";
import { useBuildingFields } from "./hooks/useBuildingFields";
import { useBuildingInitialValues } from "./hooks/useBuildingInitialValues";
import { useBuildingOptions } from "./hooks/useBuildingOptions";
import { useBuildingPrefecturesAndCities } from "./hooks/useBuildingPrefecturesAndCities";
import { useBuildingStationSearch } from "./hooks/useBuildingStationSearch";
import { useBuildingStations } from "./hooks/useBuildingStations";
import { useBuildingSubmit } from "./hooks/useBuildingSubmit";

type Props = { buildingId?: number; openTime?: Date } & Omit<
  ComponentProps<typeof Modal>,
  "children"
>;

export const BuildingFormModalContainer: FC<Props> = ({
  buildingId,
  openTime,
  ...modal
}) => {
  const { initialValues, isLoading } = useBuildingInitialValues(buildingId);

  const {
    control,
    handleSubmit,
    formState,
    watch,
    setValue,
    resetField,
    reset,
  } = useForm<BuildingFormFieldValuesType>({
    defaultValues: { ...initialValues },
    shouldUseNativeValidation: false,
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(buildingSchema),
  });

  useEffect(() => {
    reset(initialValues);
  }, [reset, openTime, initialValues]);

  const {
    isOwner,
    buildingBrandOptions,
    buildingManagementTypeOptions,
    buildingTagOptions,
    lessorBranchOptions,
  } = useBuildingOptions(buildingId, watch, setValue);

  const fields = useBuildingFields(control, setValue, resetField, watch, {
    buildingBrandOptions,
    buildingManagementTypeOptions,
    buildingTagOptions,
    lessorBranchOptions,
  });

  const { fieldErrors, hasValidationError } = useBuildingFieldErrors(
    formState.errors,
  );

  const { closeModal } = useModalContext();
  const { showToast } = useToast();

  const submittedCallback = useCallback(() => {
    showToast({
      text: `物件を${
        typeof buildingId === "undefined" ? "追加" : "保存"
      }しました。`,
      type: "Success",
    });
    closeModal();
    reset();
  }, [showToast, closeModal, reset, buildingId]);
  const {
    hasError: hasSubmissionError,
    onSubmit,
    isSubmitting,
  } = useBuildingSubmit(handleSubmit, setValue, buildingId, submittedCallback);

  const { prefectureSelectProps, wardSelectProps, citySelectProps } =
    useBuildingPrefecturesAndCities(
      watch("prefecturePidanId"),
      fields.prefecturePidanId.onChange,
      fields.prefecturePidanId.onBlur,
      watch("wardPidanId"),
      fields.wardPidanId.onChange,
      fields.wardPidanId.onBlur,
      watch("cityPidanId"),
      fields.cityPidanId.onChange,
      fields.cityPidanId.onBlur,
    );

  const { linesByStationId, stationNameByStationId } = useBuildingStations(
    watch("nearbyStations")
      .map((station) => station.stationId)
      .filter((stationId) => !!stationId),
  );

  const stationsBySearchQuery = useBuildingStationSearch(
    watch("nearbyStations")
      .map((station) => station.searchQuery)
      .filter((searchQuery): searchQuery is string => !!searchQuery),
  );

  return (
    <BuildingFormModal
      modal={modal}
      fields={fields}
      isFieldShown={{
        brand: buildingBrandOptions.length > 0,
        labels: buildingTagOptions.length > 0,
        lessorBranch: lessorBranchOptions.length > 0,
        managementType: buildingManagementTypeOptions.length > 0,
      }}
      fieldErrors={fieldErrors}
      hasErrors={{
        validation: hasValidationError,
        submission: hasSubmissionError,
      }}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
      isOwner={isOwner}
      prefectureSelectProps={prefectureSelectProps}
      wardSelectProps={wardSelectProps}
      citySelectProps={citySelectProps}
      stationNameByStationId={stationNameByStationId ?? {}}
      linesByStationId={linesByStationId ?? {}}
      stationsBySearchQuery={stationsBySearchQuery ?? {}}
    />
  );
};
