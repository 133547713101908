import { ChangeEvent, ComponentProps, FocusEvent } from "react";
import {
  Control,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
  useController,
  useFieldArray,
} from "react-hook-form";
import { BuildingFormModal } from "../../../../../components/modals/BuildingFormModal";
import {
  DEFAULT_OPTION_VALUE,
  Selectbox,
} from "../../../../../components/uiParts/dataEntry/Selectbox/base";
import SelectboxMultiple from "../../../../../components/uiParts/dataEntry/SelectboxMultiple";
import {
  GraphQLAirConditionerType,
  GraphQLBuildingUse,
  GraphQLEarthquakeResistantStructure,
  GraphQLElevatorAccessControl,
  GraphQLFloorMaterialType,
  GraphQLJanitor,
  GraphQLOaFloorType,
  GraphQLSelfLocking,
  GraphQLStructureType,
} from "../../../../../graphql";
import { formatEarthquakeResistantStructure } from "../../../../../utils/BuildingFormatter";
import { BuildingFormFieldValuesType } from "../../../../../validations/schemas/buildingForm/types";

const BUILDING_USE_OPTIONS: {
  label: string;
  value: GraphQLBuildingUse;
}[] = [
  { label: "事務所", value: GraphQLBuildingUse.Office },
  { label: "店舗", value: GraphQLBuildingUse.Retail },
  { label: "住宅", value: GraphQLBuildingUse.Residential },
  { label: "物流施設", value: GraphQLBuildingUse.Logistics },
  { label: "ホテル", value: GraphQLBuildingUse.Hotel },
  { label: "その他", value: GraphQLBuildingUse.Other },
];

const SELF_LOCKING_OPTIONS: { label: string; value: GraphQLSelfLocking }[] = [
  {
    label: "あり",
    value: GraphQLSelfLocking.Equipped,
  },
  {
    label: "なし",
    value: GraphQLSelfLocking.NotEquipped,
  },
  {
    label: "夜間のみ",
    value: GraphQLSelfLocking.NightOnly,
  },
];

const STRUCTURE_TYPE_OPTIONS: {
  label: string;
  value: GraphQLStructureType;
}[] = [
  {
    label: "鉄骨鉄筋コンクリート造（SRC造）",
    value: GraphQLStructureType.SteelReinforcedConcrete,
  },
  {
    label: "鉄筋コンクリート造（RC造）",
    value: GraphQLStructureType.ReinforcedConcrete,
  },
  {
    label: "鉄骨造（S造）",
    value: GraphQLStructureType.Steel,
  },
  {
    label: "木造（W造）",
    value: GraphQLStructureType.Wood,
  },
  {
    label: "コンクリート充填鋼管構造（CFT造）",
    value: GraphQLStructureType.ConcreteFilledSteelPipe,
  },
  {
    label: "その他（テキスト入力）",
    value: GraphQLStructureType.Other,
  },
];

const AIR_CONDITION_TYPE_OPTIONS: {
  label: string;
  value: GraphQLAirConditionerType;
}[] = [
  { label: "個別", value: GraphQLAirConditionerType.Individual },
  { label: "セントラル+個別", value: GraphQLAirConditionerType.Combined },
  { label: "セントラル", value: GraphQLAirConditionerType.Central },
  { label: "その他（テキスト入力）", value: GraphQLAirConditionerType.Other },
];

const JANITOR_OPTIONS: {
  label: string;
  value: GraphQLJanitor;
}[] = [
  { label: "なし", value: GraphQLJanitor.None },
  { label: "常駐", value: GraphQLJanitor.Stationed },
];

const ELEVATOR_ACCESS_CONTROL_OPTIONS: {
  label: string;
  value: GraphQLElevatorAccessControl;
}[] = [
  { label: "あり", value: GraphQLElevatorAccessControl.Equipped },
  { label: "なし", value: GraphQLElevatorAccessControl.NotEquipped },
];

const EARTHQUAKE_RESISTANT_STRUCTURE_OPTIONS: {
  label: string;
  value: GraphQLEarthquakeResistantStructure;
}[] = [
  {
    label: formatEarthquakeResistantStructure(
      GraphQLEarthquakeResistantStructure.NewStandard,
    ),
    value: GraphQLEarthquakeResistantStructure.NewStandard,
  },
  {
    label: formatEarthquakeResistantStructure(
      GraphQLEarthquakeResistantStructure.OldStandardReinforced,
    ),
    value: GraphQLEarthquakeResistantStructure.OldStandardReinforced,
  },
  {
    label: formatEarthquakeResistantStructure(
      GraphQLEarthquakeResistantStructure.OldStandard,
    ),
    value: GraphQLEarthquakeResistantStructure.OldStandard,
  },
];

const OA_FLOOR_TYPE_OPTIONS: {
  label: string;
  value: GraphQLOaFloorType;
}[] = [
  { label: "あり", value: GraphQLOaFloorType.Equipped },
  { label: "あり（一部）", value: GraphQLOaFloorType.PartiallyEquipped },
  { label: "なし", value: GraphQLOaFloorType.NotEquipped },
  { label: "敷設予定", value: GraphQLOaFloorType.ToBeEquipped },
];

const FLOOR_MATERIAL_TYPE_OPTIONS: {
  label: string;
  value: GraphQLFloorMaterialType;
}[] = [
  { label: "タイルカーペット", value: GraphQLFloorMaterialType.TileCarpet },
  { label: "Pタイル", value: GraphQLFloorMaterialType.PTile },
  { label: "塩ビシート", value: GraphQLFloorMaterialType.PvcSheet },
  { label: "フローリング", value: GraphQLFloorMaterialType.Flooring },
  { label: "コンクリート", value: GraphQLFloorMaterialType.Concrete },
  { label: "フロアダクト", value: GraphQLFloorMaterialType.FloorDuct },
  { label: "その他（テキスト入力）", value: GraphQLFloorMaterialType.Others },
];

export const useBuildingFields = (
  control: Control<BuildingFormFieldValuesType>,
  setValue: UseFormSetValue<BuildingFormFieldValuesType>,
  resetField: UseFormResetField<BuildingFormFieldValuesType>,
  watch: UseFormWatch<BuildingFormFieldValuesType>,
  options: {
    buildingBrandOptions: ComponentProps<typeof Selectbox>["options"];
    lessorBranchOptions: ComponentProps<typeof Selectbox>["options"];
    buildingManagementTypeOptions: ComponentProps<typeof Selectbox>["options"];
    buildingTagOptions: ComponentProps<typeof SelectboxMultiple>["checkboxes"];
  },
): ComponentProps<typeof BuildingFormModal>["fields"] => {
  const {
    fields: oldNamesFieldsRaw,
    append: appendOldName,
    remove: removeOldName,
  } = useFieldArray({ control, name: "oldNames" });
  const {
    fields: nearbyStationFieldsRaw,
    append: appendNearbyStation,
    remove: removeNearbyStation,
  } = useFieldArray({ control, name: "nearbyStations" });

  const oldNamesFields = oldNamesFieldsRaw.map(({ id }, index) => {
    const key = `oldNames.${index}` as const;

    const handler = <
      T extends ChangeEvent<E> | FocusEvent<E>,
      E extends HTMLInputElement | HTMLSelectElement,
    >(
      event: T,
    ) => {
      const { name: targetName, value } = event.target;
      setValue(
        // TODO type guard を入れる
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        targetName as any,
        value !== DEFAULT_OPTION_VALUE ? value : undefined,
        {
          shouldValidate: event.type === "blur",
        },
      );
    };

    return {
      id,
      items: {
        name: {
          name: `${key}.name` as const,
          value: watch(`${key}.name`),
        },
        year: {
          name: `${key}.year` as const,
          value: watch(`${key}.year`),
          onChange: (newValue: number | undefined) =>
            newValue !== undefined
              ? setValue(`${key}.year`, newValue, { shouldValidate: true })
              : resetField(`${key}.year`),
        },
        month: {
          name: `${key}.month` as const,
          value: watch(`${key}.month`),
          onChange: (newValue: number | undefined) =>
            newValue !== undefined
              ? setValue(`${key}.month`, newValue, { shouldValidate: true })
              : resetField(`${key}.month`),
        },
      },
      onChange: handler,
      onBlur: handler,
      onRemove: () => removeOldName(index),
    };
  });

  const nearbyStationsFields = nearbyStationFieldsRaw.map(({ id }, index) => {
    const key = `nearbyStations.${index}` as const;

    const handler = <
      T extends ChangeEvent<E> | FocusEvent<E>,
      E extends HTMLInputElement | HTMLSelectElement,
    >(
      event: T,
    ) => {
      const { name: targetName, value } = event.target;
      setValue(
        // TODO type guard を入れる
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        targetName as any,
        value !== DEFAULT_OPTION_VALUE ? value : undefined,
        {
          shouldValidate: event.type === "blur",
        },
      );
    };

    return {
      id,
      items: {
        exitName: {
          name: `${key}.exitName` as const,
          value: watch(`${key}.exitName`),
        },
        lineId: {
          name: `${key}.lineId` as const,
          value: watch(`${key}.lineId`),
          onChange: (newValue: number | undefined) =>
            setValue(`${key}.lineId`, newValue, { shouldValidate: true }),
        },
        stationId: {
          name: `${key}.stationId` as const,
          value: watch(`${key}.stationId`),
          onChange: (newValue: number | undefined) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(`${key}.stationId`, newValue as any, {
              shouldValidate: true,
            }),
        },
        timeRequired: {
          name: `${key}.timeRequired` as const,
          value: watch(`${key}.timeRequired`),
        },
        searchQuery: {
          name: `${key}.searchQuery` as const,
          value: watch(`${key}.searchQuery`),
          onChange: (newValue: string | undefined) =>
            setValue(`${key}.searchQuery`, newValue),
        },
      },
      onChange: handler,
      onBlur: handler,
      onRemove: () => removeNearbyStation(index),
    };
  });
  return {
    oldNames: {
      fields: oldNamesFields,
      // TODO: any をどうにか解決したいが、number が必須入力のため考慮が必要そう
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onAppend: () => appendOldName({} as any),
    },
    nearbyStations: {
      fields: nearbyStationsFields,
      // TODO: any をどうにか解決したいが、number が必須入力のため考慮が必要そう
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onAppend: () => appendNearbyStation({} as any),
    },

    name: useController({ control, name: "name" }).field,
    latitude: useController({ control, name: "latitude" }).field,
    longitude: useController({ control, name: "longitude" }).field,
    groundfloor: useController({ control, name: "groundfloor" }).field,
    basement: useController({ control, name: "basement" }).field,
    structureText: useController({ control, name: "structureText" }).field,
    grossFloorArea: useController({ control, name: "grossFloorArea" }).field,
    netLeasableAreaTotal: useController({
      control,
      name: "netLeasableAreaTotal",
    }).field,
    security: useController({ control, name: "security" }).field,
    elevatorNum: useController({ control, name: "elevatorNum" }).field,
    elevatorCapacity: useController({ control, name: "elevatorCapacity" })
      .field,
    parkingLotTotal: useController({ control, name: "parkingLotTotal" }).field,
    parkingLotVacant: useController({ control, name: "parkingLotVacant" })
      .field,
    powerCapacity: useController({ control, name: "powerCapacity" }).field,
    powerCapacitySideNote: useController({
      control,
      name: "powerCapacitySideNote",
    }).field,
    airConditionText: useController({ control, name: "airConditionText" })
      .field,
    emergencyGenerator: useController({ control, name: "emergencyGenerator" })
      .field,
    standardFloorArea: useController({ control, name: "standardFloorArea" })
      .field,
    standardUnitFloorHeight: useController({
      control,
      name: "standardUnitFloorHeight",
    }).field,
    standardUnitFloorHeightSideNote: useController({
      control,
      name: "standardUnitFloorHeightSideNote",
    }).field,
    standardUnitFloorLoading: useController({
      control,
      name: "standardUnitFloorLoading",
    }).field,
    standardUnitFloorLoadingSideNote: useController({
      control,
      name: "standardUnitFloorLoadingSideNote",
    }).field,
    standardUnitFloorMaterialSideNote: useController({
      control,
      name: "standardUnitFloorMaterialSideNote",
    }).field,
    standardUnitOaFloorHeight: useController({
      control,
      name: "standardUnitOaFloorHeight",
    }).field,
    explanation: useController({ control, name: "explanation" }).field,
    internalExplanation: useController({ control, name: "internalExplanation" })
      .field,
    prefecturePidanId: useController({ control, name: "prefecturePidanId" })
      .field,
    wardPidanId: useController({ control, name: "wardPidanId" }).field,
    cityPidanId: useController({ control, name: "cityPidanId" }).field,
    pidanBlock: useController({ control, name: "pidanBlock" }).field,
    completedYear: useController({ control, name: "completedYear" }).field,
    completedMonth: useController({ control, name: "completedMonth" }).field,
    renewalYear: useController({ control, name: "renewalYear" }).field,
    renewalMonth: useController({ control, name: "renewalMonth" }).field,
    structureType: {
      ...useController({ control, name: "structureType" }).field,
      options: STRUCTURE_TYPE_OPTIONS,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetValue: () => setValue("structureType", undefined as any),
    },
    buildingUse: {
      ...useController({ control, name: "buildingUse" }).field,
      options: BUILDING_USE_OPTIONS,
      resetValue: () => setValue("buildingUse", undefined),
    },
    earthquakeResistantStructure: {
      ...useController({ control, name: "earthquakeResistantStructure" }).field,
      options: EARTHQUAKE_RESISTANT_STRUCTURE_OPTIONS,
      resetValue: () =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setValue("earthquakeResistantStructure", undefined as any),
    },
    janitor: {
      ...useController({ control, name: "janitor" }).field,
      options: JANITOR_OPTIONS,
      resetValue: () => setValue("janitor", undefined),
    },
    selfLocking: {
      ...useController({ control, name: "selfLocking" }).field,
      options: SELF_LOCKING_OPTIONS,
      resetValue: () => setValue("selfLocking", undefined),
    },
    airConditionType: {
      ...useController({ control, name: "airConditionType" }).field,
      options: AIR_CONDITION_TYPE_OPTIONS,
      resetValue: () => setValue("airConditionType", undefined),
    },
    elevatorAccessControl: {
      ...useController({ control, name: "elevatorAccessControl" }).field,
      options: ELEVATOR_ACCESS_CONTROL_OPTIONS,
      resetValue: () => setValue("elevatorAccessControl", undefined),
    },
    standardUnitFloorMaterial: {
      ...useController({ control, name: "standardUnitFloorMaterial" }).field,
      options: FLOOR_MATERIAL_TYPE_OPTIONS,
      resetValue: () => setValue("standardUnitFloorMaterial", undefined),
    },
    standardUnitOaFloorType: {
      ...useController({ control, name: "standardUnitOaFloorType" }).field,
      options: OA_FLOOR_TYPE_OPTIONS,
      resetValue: () => setValue("standardUnitOaFloorType", undefined),
    },
    buildingBrandId: {
      ...useController({ control, name: "buildingBrandId" }).field,
      options: options.buildingBrandOptions,
      resetValue: () => setValue("buildingBrandId", undefined),
    },
    buildingManagementTypeId: {
      ...useController({ control, name: "buildingManagementTypeId" }).field,
      options: options.buildingManagementTypeOptions,
      resetValue: () => setValue("buildingManagementTypeId", undefined),
    },
    lessorBranchId: {
      ...useController({ control, name: "lessorBranchId" }).field,
      options: options.lessorBranchOptions,
      resetValue: () => setValue("lessorBranchId", undefined),
    },
    buildingTagIds: {
      ...useController({ control, name: "buildingTagIds" }).field,
      options: options.buildingTagOptions,
    },
    buildingImageExteriorUrl: useController({
      control,
      name: "buildingImageExteriorUrl",
    }).field,
    buildingImageVicinityMapUrl: useController({
      control,
      name: "buildingImageVicinityMapUrl",
    }).field,
    buildingImageExteriorFile: useController({
      control,
      name: "buildingImageExteriorFile",
    }).field,
    buildingImageVicinityMapFile: useController({
      control,
      name: "buildingImageVicinityMapFile",
    }).field,
    facilityDetailTwentyFourSeven: {
      ...useController({
        control,
        name: "facilityDetailTwentyFourSeven",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailTwentyFourSeven",
          !watch("facilityDetailTwentyFourSeven"),
        );
      },
    },
    facilityDetailWashlet: {
      ...useController({
        control,
        name: "facilityDetailWashlet",
      }).field,
      onToggle: () => {
        setValue("facilityDetailWashlet", !watch("facilityDetailWashlet"));
      },
    },
    facilityDetailSeparateWc: {
      ...useController({
        control,
        name: "facilityDetailSeparateWc",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailSeparateWc",
          !watch("facilityDetailSeparateWc"),
        );
      },
    },
    facilityDetailOpticalFiber: {
      ...useController({
        control,
        name: "facilityDetailOpticalFiber",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailOpticalFiber",
          !watch("facilityDetailOpticalFiber"),
        );
      },
    },
    facilityDetailRefreshingSpace: {
      ...useController({
        control,
        name: "facilityDetailRefreshingSpace",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailRefreshingSpace",
          !watch("facilityDetailRefreshingSpace"),
        );
      },
    },
    facilityDetailSmokingRoom: {
      ...useController({
        control,
        name: "facilityDetailSmokingRoom",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailSmokingRoom",
          !watch("facilityDetailSmokingRoom"),
        );
      },
    },
    facilityDetailAlongMainStreet: {
      ...useController({
        control,
        name: "facilityDetailAlongMainStreet",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailAlongMainStreet",
          !watch("facilityDetailAlongMainStreet"),
        );
      },
    },
    facilityDetailMuchSunshine: {
      ...useController({
        control,
        name: "facilityDetailMuchSunshine",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailMuchSunshine",
          !watch("facilityDetailMuchSunshine"),
        );
      },
    },
    facilityDetailEco: {
      ...useController({
        control,
        name: "facilityDetailEco",
      }).field,
      onToggle: () => {
        setValue("facilityDetailEco", !watch("facilityDetailEco"));
      },
    },
    facilityDetailNursery: {
      ...useController({
        control,
        name: "facilityDetailNursery",
      }).field,
      onToggle: () => {
        setValue("facilityDetailNursery", !watch("facilityDetailNursery"));
      },
    },
    facilityDetailEmergencyPower: {
      ...useController({
        control,
        name: "facilityDetailEmergencyPower",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailEmergencyPower",
          !watch("facilityDetailEmergencyPower"),
        );
      },
    },
    facilityDetailCafeteria: {
      ...useController({
        control,
        name: "facilityDetailCafeteria",
      }).field,
      onToggle: () => {
        setValue("facilityDetailCafeteria", !watch("facilityDetailCafeteria"));
      },
    },
    facilityDetailDirectAccessToStation: {
      ...useController({
        control,
        name: "facilityDetailDirectAccessToStation",
      }).field,
      onToggle: () => {
        setValue(
          "facilityDetailDirectAccessToStation",
          !watch("facilityDetailDirectAccessToStation"),
        );
      },
    },
    facilityDetailConcrete: {
      ...useController({
        control,
        name: "facilityDetailConcrete",
      }).field,
      onToggle: () => {
        setValue("facilityDetailConcrete", !watch("facilityDetailConcrete"));
      },
    },
    facilityDetailSoho: {
      ...useController({
        control,
        name: "facilityDetailSoho",
      }).field,
      onToggle: () => {
        setValue("facilityDetailSoho", !watch("facilityDetailSoho"));
      },
    },
    facilityDetailHighGrade: {
      ...useController({
        control,
        name: "facilityDetailHighGrade",
      }).field,
      onToggle: () => {
        setValue("facilityDetailHighGrade", !watch("facilityDetailHighGrade"));
      },
    },
  };
};
