import { useMemo } from "react";
import Selectbox, { Option } from "../../../../uiParts/dataEntry/Selectbox";
import { Line, Station } from "./types";

type Props = {
  lines: Line[];
  currentStation?: Station;
  currentLine?: Line;

  onSelect: (line: Line | undefined) => void;
};

const LineSelect: React.FC<Props> = ({
  lines,
  currentStation,
  currentLine,
  onSelect,
}) => {
  const options = useMemo<Option[]>(() => {
    return lines.map((line) => ({
      label: line.name,
      value: line.id.toString(),
    }));
  }, [lines]);

  return (
    <>
      <Selectbox
        name="building-station-line"
        options={options}
        optionDefaultText="選択してください"
        label="路線(任意)"
        value={currentLine ? currentLine.id : undefined}
        onChange={(selectedValue) => {
          if (!selectedValue || !currentStation) {
            onSelect(undefined);
          } else {
            const line = lines.find(
              ({ id }) => selectedValue === id.toString(),
            );
            const newLine = line ? { id: line.id, name: line.name } : undefined;
            onSelect(newLine);
          }
        }}
      />
    </>
  );
};

export default LineSelect;
