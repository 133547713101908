import { useState } from "react";
import { GraphQLPrefecture } from "../../../../graphql";
import Autocomplete from "../../../uiParts/dataEntry/Autocomplete";

type Props = {
  currentPrefecture?: string;
  prefectures: Pick<GraphQLPrefecture, "id" | "name">[];
  onSelect: (prefecture: Pick<GraphQLPrefecture, "id" | "name">) => void;
  onDelete: () => void;
  isRequired?: boolean;
  testId?: string;
};

const PrefectureSelect: React.FC<Props> = ({
  currentPrefecture,
  prefectures,
  onSelect,
  onDelete,
  isRequired,
  testId,
}) => {
  const { searchedPrefectures, prefectureSearchText, setSearchText } =
    usePrefectureSearch(prefectures);

  return (
    <>
      <Autocomplete
        name="prefecture"
        label="都道府県"
        isRequired={isRequired}
        placeholder="東京都"
        value={currentPrefecture ? currentPrefecture : prefectureSearchText}
        onChange={(value) => setSearchText(value)}
        menuItems={searchedPrefectures.map((prefecture) => {
          return {
            label: prefecture.name,
            onClick: () => onSelect(prefecture),
          };
        })}
        onDelete={() => {
          setSearchText("");
          onDelete();
        }}
        isSelected={Boolean(currentPrefecture)}
        testId={testId}
      />
    </>
  );
};

const usePrefectureSearch = (
  prefectures: Pick<GraphQLPrefecture, "id" | "name">[],
) => {
  const [prefectureSearchText, setSearchText] = useState<string>("");
  const searchedPrefectures = prefectures.filter((prefecture) =>
    prefecture.name.includes(prefectureSearchText),
  );
  return { searchedPrefectures, prefectureSearchText, setSearchText };
};

export default PrefectureSelect;
