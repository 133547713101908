import { useMemo } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useQuery } from "urql";
import {
  BuildingModalBuildingOwnerLessorOptionsDocument,
  BuildingModalSelfLessorOptionsDocument,
} from "../../../../../graphql";
import { BuildingFormFieldValuesType } from "../../../../../validations/schemas/buildingForm/types";

export const useBuildingOptions = (
  buildingId: number | undefined,
  watch: UseFormWatch<BuildingFormFieldValuesType>,
  setValue: UseFormSetValue<BuildingFormFieldValuesType>,
) => {
  const buildingIdVariables = useMemo(
    () => ({ buildingId: buildingId ?? 0 }),
    [buildingId],
  );
  const [{ data: ownerLessorOptionsData }] = useQuery({
    query: BuildingModalBuildingOwnerLessorOptionsDocument,
    variables: buildingIdVariables,
    pause: !buildingId, // ビルデータがある場合（更新の時）オーナーのデータが欲しい
  });
  const [{ data: selfLessorOptionsData }] = useQuery({
    query: BuildingModalSelfLessorOptionsDocument,
    pause: !!buildingId, // ビルデータがない場合（作成の時）自身のデータが欲しい
  });
  const lessorOptions =
    ownerLessorOptionsData?.building.lessor || selfLessorOptionsData?.lessor;

  const isOwner = ownerLessorOptionsData?.building.isOwner ?? true;

  const buildingBrandOptions = useMemo(() => {
    return (
      lessorOptions?.buildingBrands?.map((brand) => ({
        label: brand.label,
        value: `${brand.id}`,
      })) ?? []
    );
  }, [lessorOptions?.buildingBrands]);

  const lessorBranchOptions = useMemo(() => {
    return (
      lessorOptions?.lessorBranches?.map((lessorBranch) => ({
        label: lessorBranch.name,
        value: `${lessorBranch.id}`,
      })) ?? []
    );
  }, [lessorOptions?.lessorBranches]);

  const buildingManagementTypeOptions = useMemo(() => {
    return (
      lessorOptions?.buildingManagementTypes?.map((managementType) => ({
        label: managementType.label,
        value: `${managementType.id}`,
      })) ?? []
    );
  }, [lessorOptions?.buildingManagementTypes]);

  const buildingTagOptions =
    lessorOptions?.buildingTags?.map((tag) => {
      const selectedTags = watch("buildingTagIds") ?? [];
      const isSelected = selectedTags.includes(tag.id);
      const onToggle = () => {
        setValue(
          "buildingTagIds",
          isSelected
            ? selectedTags.filter((id) => id !== tag.id)
            : [...selectedTags, tag.id],
        );
      };
      return {
        label: tag.label,
        isSelected,
        onClickCheckBox: onToggle,
      };
    }) ?? [];

  return {
    isOwner,
    buildingBrandOptions,
    lessorBranchOptions,
    buildingManagementTypeOptions,
    buildingTagOptions,
  };
};
