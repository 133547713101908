import { z } from "zod";
import {
  GraphQLAirConditionerType,
  GraphQLBuildingUse,
  GraphQLEarthquakeResistantStructure,
  GraphQLElevatorAccessControl,
  GraphQLFloorMaterialType,
  GraphQLJanitor,
  GraphQLOaFloorType,
  GraphQLSelfLocking,
  GraphQLStructureType,
} from "../../../graphql";
import { zDecimal } from "../../../utils/zodCustomSchema";
import {
  falsyToUndefined,
  requiredErrorMessageErrorMap,
  nonEmptyStringToNumberOrNaN,
} from "../../utils";

export const buildingOldNameSchema = z.object({
  name: z.preprocess(
    falsyToUndefined,
    z.string({ errorMap: requiredErrorMessageErrorMap("旧名称") }),
  ),
  year: z.preprocess(
    nonEmptyStringToNumberOrNaN,
    z.number({ errorMap: requiredErrorMessageErrorMap("名称変更年") }).int(),
  ),
  month: z.preprocess(
    nonEmptyStringToNumberOrNaN,
    z.number({ errorMap: requiredErrorMessageErrorMap("名称変更月") }).int(),
  ),
});

export const buildingNearbyStationSchema = z.object({
  searchQuery: z.string().optional(),
  exitName: z.preprocess(falsyToUndefined, z.string().optional()),
  lineId: z.preprocess(
    nonEmptyStringToNumberOrNaN,
    z.number().int().optional(),
  ),
  stationId: z.preprocess(
    nonEmptyStringToNumberOrNaN,
    z.number({ errorMap: requiredErrorMessageErrorMap("最寄駅") }).int(),
  ),
  timeRequired: z.preprocess(
    nonEmptyStringToNumberOrNaN,
    z.number({ errorMap: requiredErrorMessageErrorMap("徒歩時間") }).int(),
  ),
});

export const buildingSchema = z
  .object({
    buildingCode: z.string().nullable().optional(),
    lmLessorUserId: z.number().int().optional(),
    pmLessorUserId: z.number().int().optional(),
    name: z.preprocess(
      falsyToUndefined,
      z.string({ errorMap: requiredErrorMessageErrorMap("物件名") }),
    ),
    nameReading: z.preprocess(falsyToUndefined, z.string().optional()),
    oldNames: z.array(buildingOldNameSchema).default([]),
    prefecturePidanId: z.number().int(),
    wardPidanId: z.number().int(),
    cityPidanId: z.number().int(),
    pidanBlock: z.preprocess(falsyToUndefined, z.string().optional()),
    latitude: z.preprocess(
      falsyToUndefined,
      z
        .preprocess(Number, z.number().positive())
        .and(z.preprocess(String, zDecimal.transform(Number)))
        .transform(String)
        .optional(),
    ),
    longitude: z.preprocess(
      falsyToUndefined,
      z
        .preprocess(Number, z.number().positive())
        .and(z.preprocess(String, zDecimal.transform(Number)))
        .transform(String)
        .optional(),
    ),
    completedYear: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number({ errorMap: requiredErrorMessageErrorMap("竣工年") }),
    ),
    completedMonth: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number({ errorMap: requiredErrorMessageErrorMap("竣工月") }),
    ),
    renewalYear: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().optional(),
    ),
    renewalMonth: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().optional(),
    ),
    groundfloor: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number({ errorMap: requiredErrorMessageErrorMap("地上階数") }),
    ),
    basement: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z
        .number({ errorMap: requiredErrorMessageErrorMap("地下階数") })
        .default(0),
    ),
    structureType: z.nativeEnum(GraphQLStructureType).optional(),
    structureText: z.preprocess(falsyToUndefined, z.string().optional()),
    grossFloorArea: z.preprocess(
      falsyToUndefined,
      z
        .preprocess(Number, z.number().positive())
        .and(z.preprocess(String, zDecimal.transform(Number)))
        .transform(String)
        .optional(),
    ),
    netLeasableAreaTotal: z.preprocess(
      falsyToUndefined,
      z
        .preprocess(Number, z.number().positive())
        .and(z.preprocess(String, zDecimal.transform(Number)))
        .transform(String)
        .optional(),
    ),
    buildingUse: z.nativeEnum(GraphQLBuildingUse).optional(),
    earthquakeResistantStructure: z.nativeEnum(
      GraphQLEarthquakeResistantStructure,
    ),
    janitor: z.nativeEnum(GraphQLJanitor).optional(),
    selfLocking: z.nativeEnum(GraphQLSelfLocking).optional(),
    security: z.preprocess(falsyToUndefined, z.string().optional()),
    elevatorNum: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().nonnegative().optional(),
    ),
    elevatorCapacity: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().positive().optional(),
    ),
    elevatorAccessControl: z
      .nativeEnum(GraphQLElevatorAccessControl)
      .optional(),
    parkingLotTotal: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    parkingLotVacant: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    airConditionType: z.nativeEnum(GraphQLAirConditionerType).optional(),
    airConditionText: z.preprocess(falsyToUndefined, z.string().optional()),
    powerCapacity: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    powerCapacitySideNote: z.preprocess(
      falsyToUndefined,
      z.string().optional(),
    ),
    emergencyGenerator: z.preprocess(falsyToUndefined, z.string().optional()),
    facilityDetailTwentyFourSeven: z.boolean(),
    facilityDetailWashlet: z.boolean(),
    facilityDetailSeparateWc: z.boolean(),
    facilityDetailOpticalFiber: z.boolean(),
    facilityDetailRefreshingSpace: z.boolean(),
    facilityDetailSmokingRoom: z.boolean(),
    facilityDetailAlongMainStreet: z.boolean(),
    facilityDetailMuchSunshine: z.boolean(),
    facilityDetailEco: z.boolean(),
    facilityDetailNursery: z.boolean(),
    facilityDetailEmergencyPower: z.boolean(),
    facilityDetailCafeteria: z.boolean(),
    facilityDetailDirectAccessToStation: z.boolean(),
    facilityDetailConcrete: z.boolean(),
    facilityDetailSoho: z.boolean(),
    facilityDetailHighGrade: z.boolean(),
    explanation: z.preprocess(falsyToUndefined, z.string().optional()),
    internalExplanation: z.preprocess(falsyToUndefined, z.string().optional()),
    standardFloorArea: z
      .preprocess(Number, z.number().positive())
      .and(z.preprocess(String, zDecimal.transform(Number)))
      .transform(String),
    standardUnitFloorHeight: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    standardUnitFloorHeightSideNote: z.preprocess(
      falsyToUndefined,
      z.string().optional(),
    ),
    standardUnitFloorLoading: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    standardUnitFloorLoadingSideNote: z.preprocess(
      falsyToUndefined,
      z.string().optional(),
    ),
    standardUnitFloorMaterial: z
      .nativeEnum(GraphQLFloorMaterialType)
      .optional(),
    standardUnitFloorMaterialSideNote: z.preprocess(
      falsyToUndefined,
      z.string().optional(),
    ),
    standardUnitOaFloorType: z.nativeEnum(GraphQLOaFloorType).optional(),
    standardUnitOaFloorHeight: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    nearbyStations: z
      .array(buildingNearbyStationSchema)
      .min(1, "最寄駅は一件以上登録してください")
      .superRefine((val, ctx) => {
        const stationLineIds = val.map((station) =>
          JSON.stringify([station.lineId, station.stationId]),
        );
        console.log(new Set(stationLineIds).size, stationLineIds.length);
        if (new Set(stationLineIds).size !== stationLineIds.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "同じ駅かつ同じ路線を二つ以上登録できません",
          });
        }
      }),
    buildingTagIds: z.array(z.number().int()).default([]),
    buildingBrandId: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    buildingManagementTypeId: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    lessorBranchId: z.preprocess(
      nonEmptyStringToNumberOrNaN,
      z.number().int().optional(),
    ),
    buildingImageExteriorFile: z.custom<File>().optional(),
    buildingImageExteriorUrl: z.string().optional(),
    buildingImageVicinityMapFile: z.custom<File>().optional(),
    buildingImageVicinityMapUrl: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (
      val.standardUnitFloorMaterial === GraphQLFloorMaterialType.Others &&
      !val.standardUnitFloorMaterialSideNote
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "床仕様を入力してください",
        path: ["standardUnitFloorMaterialSideNote"],
      });
    }

    if (
      val.airConditionType === GraphQLAirConditionerType.Other &&
      !val.airConditionText
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "空調を入力してください",
        path: ["airConditionText"],
      });
    }

    if (
      val.structureType === GraphQLStructureType.Other &&
      !val.structureText
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "構造を入力してください",
        path: ["structureText"],
      });
    }

    if (
      val.parkingLotTotal !== undefined &&
      val.parkingLotVacant !== undefined &&
      val.parkingLotTotal < val.parkingLotVacant
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "駐車場の空き台数は総台数以下にしてください",
        path: ["parkingLotVacant"],
      });
    }
  });
