import { css, SerializedStyles } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React, { ReactNode } from "react";
import { Color, LineHeight, Spacing } from "../../../../tokens";

type HeadProps = {
  headItems: HeadItem[];
  isNoBorder?: boolean;
  className?: string;
};

type HeadItem = {
  content: ReactNode;
  maxWidth?: React.CSSProperties["maxWidth"];
  minWidth?: React.CSSProperties["minWidth"];
  textAlign?: keyof typeof TextAlignType;
  className?: string;
  css?: SerializedStyles;
};

const TextAlignType = {
  Left: "left",
  Center: "center",
  Right: "right",
};

export const Head: React.FC<HeadProps> = ({
  headItems,
  isNoBorder,
  className,
}) => {
  return (
    <ul css={head} className={className}>
      {headItems.map((item: HeadItem, index) => {
        return (
          <li
            key={index}
            css={[
              headItem(
                item.maxWidth,
                item.minWidth,
                item.textAlign,
                isNoBorder,
              ),
              item.css,
            ]}
            className={item.className}
          >
            {item.content}
          </li>
        );
      })}
    </ul>
  );
};

const head = css`
  display: flex;
  align-items: center;
  background: ${Color.Neutral.Light.Secondary};
  border-bottom: solid 1px ${Color.Neutral.Light.Primary};
`;

const headItem = (
  maxWidth?: React.CSSProperties["maxWidth"],
  minWidth?: React.CSSProperties["minWidth"],
  textAlign?: string,
  isNoBorder?: boolean,
) => css`
  flex-grow: 1;
  width: 100%;
  max-width: ${maxWidth ? maxWidth : "none"};
  min-width: ${minWidth ? minWidth : "none"};
  font-size: ${text.size[12]};
  font-weight: bold;
  line-height: ${LineHeight[140]};
  text-align: ${textAlign || "center"};
  padding: ${Spacing[8]};
  color: ${Color.Neutral.Base.Secondary};
  &:not(:last-child) {
    border-right: solid ${isNoBorder ? 0 : "1px"} ${Color.Neutral.Light.Primary};
  }
`;

type RowProps = {
  children: React.ReactNode;
  isNoBorder?: boolean;
  className?: string;
  testId?: string;
};

export const Row: React.FC<RowProps> = ({
  children,
  isNoBorder,
  className,
  testId,
}) => {
  return (
    <ul css={row(isNoBorder)} className={className} data-testid={testId}>
      {children}
    </ul>
  );
};

const row = (isNoBorder?: boolean) => css`
  display: flex;
  border-bottom: solid 1px ${Color.Neutral.Light.Secondary};
  > * {
    flex-grow: 1;
    &:not(:last-child) {
      border-right: solid ${isNoBorder ? 0 : "1px"}
        ${Color.Neutral.Light.Secondary};
    }
  }
`;

export type CellProps = {
  children: React.ReactNode;
  horizontalAlign: keyof typeof HorizontalAlignType;
  verticalAlign: keyof typeof VerticalAlignType;
  textAlign: keyof typeof TextAlignType;
  width?: React.CSSProperties["width"];
  maxWidth?: React.CSSProperties["maxWidth"];
  minWidth?: React.CSSProperties["minWidth"];
  className?: string;
  onClick?: () => void;
};

const HorizontalAlignType = {
  Left: "flex-start",
  Center: "center",
  Right: "flex-end",
};

const VerticalAlignType = {
  Top: "flex-start",
  Center: "center",
  Bottom: "flex-end",
};

export const Cell: React.FC<CellProps> = ({
  children,
  horizontalAlign,
  verticalAlign,
  textAlign,
  width,
  maxWidth,
  minWidth,
  onClick,
  className,
}) => {
  return (
    <li
      css={cell(
        HorizontalAlignType[horizontalAlign],
        VerticalAlignType[verticalAlign],
        TextAlignType[textAlign],
        width,
        maxWidth,
        minWidth,
      )}
      className={className}
      onClick={onClick}
    >
      {children}
    </li>
  );
};

const cell = (
  horizontalAlign: string,
  verticalAlign: string,
  textAlign: string,
  width: React.CSSProperties["width"],
  maxWidth: React.CSSProperties["maxWidth"],
  minWidth: React.CSSProperties["minWidth"],
) => css`
  display: flex;
  align-items: ${verticalAlign};
  justify-content: ${horizontalAlign};
  text-align: ${textAlign};
  width: ${width ? width : "auto"};
  max-width: ${maxWidth ? maxWidth : "none"};
  min-width: ${minWidth ? minWidth : "none"};
  color: ${Color.Neutral.Base.Primary};
  font-size: ${text.size[12]};
  line-height: ${LineHeight[140]};
  padding: ${Spacing[8]};
  white-space: pre-line;
  word-break: break-all;
`;
