import { useQuery } from "urql";
import { PidanWardsDocument } from "../graphql";

export const usePidanWards = (prefectureId?: number) => {
  // prefectureId が変わるたびに refetch する必要があるので独立しておいたほうが良さそうという判断
  const [{ data }] = useQuery({
    query: PidanWardsDocument,
    variables: { prefectureId: prefectureId ?? 0 },
    pause: !prefectureId,
  });
  return data?.pidanWards.map((ward) => ({
    id: Number(ward.id.replace("Ward/", "")),
    name: ward.name,
  }));
};
