import { FormEventHandler, useCallback, useState } from "react";
import { UseFormHandleSubmit, UseFormSetValue } from "react-hook-form";
import {
  CreateBuildingDocument,
  GraphQLBuildingImageType,
  GraphQLCreateBuildingInput,
  GraphQLUpdateBuildingInput,
  UpdateBuildingDocument,
} from "../../../../../graphql";
import { useMutationWithError } from "../../../../../graphql/useMutationWithError";
import { uploadBuildingImageFile } from "../../../../../repository/presigned";
import { BuildingFormFieldValuesType } from "../../../../../validations/schemas/buildingForm/types";

export const convertBuildingInputWithOldImages = (
  formValues: BuildingFormFieldValuesType,
): GraphQLCreateBuildingInput & Omit<GraphQLUpdateBuildingInput, "id"> => {
  return {
    buildingCode: formValues.buildingCode,
    lessorBranchId: formValues.lessorBranchId,
    lessorBranchIds: formValues.lessorBranchId
      ? [formValues.lessorBranchId]
      : [],
    name: formValues.name,
    nameReading: formValues.nameReading,
    oldNames: formValues.oldNames.map(({ name, year, month }) => ({
      name,
      renamedYear: year,
      renamedMonth: month,
    })),
    cityPidanId: formValues.cityPidanId,
    pidanBlock: formValues.pidanBlock,
    latitude: formValues.latitude,
    longitude: formValues.longitude,
    completedYear: formValues.completedYear,
    completedMonth: formValues.completedMonth,
    renewalYear: formValues.renewalYear,
    renewalMonth: formValues.renewalMonth,
    groundfloor: formValues.groundfloor,
    basement: formValues.basement,
    structure: formValues.structureType
      ? {
          structureType: formValues.structureType,
          structureText: formValues.structureText,
        }
      : undefined,
    standardFloorAreaSqm: (
      Number(formValues.standardFloorArea) / 0.3025
    ).toFixed(3), // 旧リースでは平米の値を扱わないが、必須項目なので坪から変換して入れる
    standardFloorArea: formValues.standardFloorArea,
    grossFloorArea: formValues.grossFloorArea,
    buildingUse: formValues.buildingUse,
    // 新リースで複数用途を選んでから旧リースで更新すると、用途が最初の一つのみしか保持されない
    buildingUses: formValues.buildingUse ? [formValues.buildingUse] : [],
    earthquakeResistantStructure: formValues.earthquakeResistantStructure,
    janitor: formValues.janitor,
    selfLocking: formValues.selfLocking,
    security: formValues.security,
    parkingLotTotal: formValues.parkingLotTotal,
    parkingLotVacant: formValues.parkingLotVacant,
    elevators: formValues.elevatorNum
      ? {
          num: formValues.elevatorNum,
          capacity: formValues.elevatorCapacity,
          accessControl: formValues.elevatorAccessControl,
        }
      : undefined,
    airConditioner: formValues.airConditionType
      ? {
          airConditionerType: formValues.airConditionType,
          airConditionerText: formValues.airConditionText,
        }
      : undefined,
    powerCapacity: formValues.powerCapacity,
    powerCapacitySideNote: formValues.powerCapacitySideNote,
    emergencyGenerator: formValues.emergencyGenerator,
    explanation: formValues.explanation,
    internalExplanation: formValues.internalExplanation,
    netLeasableAreaTotal: formValues.netLeasableAreaTotal,
    lmLessorUserId: formValues.lmLessorUserId,
    lmLessorUserIds: formValues.lmLessorUserId
      ? [formValues.lmLessorUserId]
      : [],
    pmLessorUserId: formValues.pmLessorUserId,
    nearbyStations: formValues.nearbyStations.map(
      ({ stationId, timeRequired, lineId, exitName }) => ({
        stationId,
        timeRequired,
        lineId,
        exitName,
      }),
    ),
    buildingBrandId: formValues.buildingBrandId,
    buildingTagIds: formValues.buildingTagIds,
    facilityDetail: {
      twentyFourSeven: formValues.facilityDetailTwentyFourSeven,
      washlet: formValues.facilityDetailWashlet,
      separateWc: formValues.facilityDetailSeparateWc,
      opticalFiber: formValues.facilityDetailOpticalFiber,
      refreshingSpace: formValues.facilityDetailRefreshingSpace,
      smokingRoom: formValues.facilityDetailSmokingRoom,
      alongMainStreet: formValues.facilityDetailAlongMainStreet,
      muchSunshine: formValues.facilityDetailMuchSunshine,
      eco: formValues.facilityDetailEco,
      nursery: formValues.facilityDetailNursery,
      emergencyPower: formValues.facilityDetailEmergencyPower,
      cafeteria: formValues.facilityDetailCafeteria,
      directAccessToStation: formValues.facilityDetailDirectAccessToStation,
      concrete: formValues.facilityDetailConcrete,
      soho: formValues.facilityDetailSoho,
      highGrade: formValues.facilityDetailHighGrade,
    },
    // PF-3515
    // TODO: モーダルで入力できるようにして値を入れる
    additionalContents: [],
    buildingManagementTypeId: formValues.buildingManagementTypeId,
    standardUnitFloorHeight: formValues.standardUnitFloorHeight,
    standardUnitFloorHeightSideNote: formValues.standardUnitFloorHeightSideNote,
    standardUnitFloorLoading: formValues.standardUnitFloorLoading,
    standardUnitFloorLoadingSideNote:
      formValues.standardUnitFloorLoadingSideNote,
    standardUnitFloorMaterial: formValues.standardUnitFloorMaterial
      ? {
          floorMaterialType: formValues.standardUnitFloorMaterial,
          floorMaterialText: formValues.standardUnitFloorMaterialSideNote,
        }
      : undefined,
    standardUnitOaFloor: formValues.standardUnitOaFloorType
      ? {
          oaFloorType: formValues.standardUnitOaFloorType,
          oaFloorHeight: formValues.standardUnitOaFloorHeight,
        }
      : undefined,
    buildingImages: [
      formValues.buildingImageExteriorUrl &&
      !formValues.buildingImageExteriorUrl?.startsWith("data:")
        ? {
            imageType: GraphQLBuildingImageType.Exterior,
            url: formValues.buildingImageExteriorUrl,
          }
        : undefined,
      formValues.buildingImageVicinityMapUrl &&
      !formValues.buildingImageVicinityMapUrl?.startsWith("data:")
        ? {
            imageType: GraphQLBuildingImageType.VicinityMap,
            url: formValues.buildingImageVicinityMapUrl,
          }
        : undefined,
    ].filter((image): image is Exclude<typeof image, undefined> => !!image),
    lessorDefinedColumns: [], // 使用しない
  };
};

export const useBuildingSubmit = (
  handleSubmit: UseFormHandleSubmit<BuildingFormFieldValuesType>,
  setValue: UseFormSetValue<BuildingFormFieldValuesType>,
  buildingId: number | undefined,
  doneCallback?: () => void,
) => {
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [, createBuilding] = useMutationWithError(CreateBuildingDocument);
  const [, updateBuilding] = useMutationWithError(UpdateBuildingDocument);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      try {
        await handleSubmit(async (data) => {
          setIsSubmitting(true);

          let input = convertBuildingInputWithOldImages(data);
          let updateBuildingId: number;

          if (!buildingId) {
            const createdBuildingId = (await createBuilding({ input })).data
              ?.createBuilding.id;
            if (!createdBuildingId) {
              throw new Error("Failed to create building!");
            }
            updateBuildingId = createdBuildingId;
          } else {
            updateBuildingId = buildingId;
            await updateBuilding({ input: { ...input, id: buildingId } });
          }

          // If new image files are added, upload them and update the building image URLs.
          if (data.buildingImageExteriorFile) {
            const url = await uploadBuildingImageFile(
              {
                buildingId: updateBuildingId,
                contentType: data.buildingImageExteriorFile.type,
              },
              data.buildingImageExteriorFile,
            );
            setValue("buildingImageExteriorUrl", url);
            setValue("buildingImageExteriorFile", undefined);
            input = {
              ...input,
              buildingImages: [
                ...input.buildingImages.filter(
                  (image) =>
                    image.imageType !== GraphQLBuildingImageType.Exterior,
                ),
                { imageType: GraphQLBuildingImageType.Exterior, url },
              ],
            };
          }

          if (data.buildingImageVicinityMapFile) {
            const url = await uploadBuildingImageFile(
              {
                buildingId: updateBuildingId,
                contentType: data.buildingImageVicinityMapFile.type,
              },
              data.buildingImageVicinityMapFile,
            );
            setValue("buildingImageVicinityMapUrl", url);
            setValue("buildingImageVicinityMapFile", undefined);
            input = {
              ...input,
              buildingImages: [
                ...input.buildingImages.filter(
                  (image) =>
                    image.imageType !== GraphQLBuildingImageType.VicinityMap,
                ),
                { imageType: GraphQLBuildingImageType.VicinityMap, url },
              ],
            };
          }

          if (
            data.buildingImageExteriorFile ||
            data.buildingImageVicinityMapFile
          ) {
            await updateBuilding({
              input: { ...input, id: updateBuildingId },
            });
          }

          doneCallback?.();
        })(event);
        setHasError(false);
      } catch (error) {
        console.log(error);
        setHasError(true);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      handleSubmit,
      buildingId,
      createBuilding,
      updateBuilding,
      setValue,
      doneCallback,
    ],
  );

  return { onSubmit, isSubmitting, hasError };
};
