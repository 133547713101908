import { ComponentProps, useEffect, useMemo, useState } from "react";
import { useClient } from "urql";
import { BuildingFormModal } from "../../../../../components/modals/BuildingFormModal";
import { StationsSearchDocument } from "../../../../../graphql";

export const useBuildingStationSearch = (
  uncachedStationSearchQueries: string[],
): ComponentProps<typeof BuildingFormModal>["stationsBySearchQuery"] => {
  const stationSearchQueries = useMemo(
    () => uncachedStationSearchQueries,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(uncachedStationSearchQueries)],
  );
  const [stationsBySearchQuery, setStationsBySearchQuery] = useState({});

  const client = useClient();

  useEffect(() => {
    // あとの更新があった場合は、前の更新を無視する
    let isLatestUpdate = true;

    void (async () => {
      // URQL がキャッシュしてくれるので、Promise.all を気にする必要がない。
      const newStations = await Promise.all(
        stationSearchQueries.map((searchQuery) =>
          client
            .query(StationsSearchDocument, { query: { name: searchQuery } })
            .toPromise()
            .then((result) => [searchQuery, result.data?.getStations ?? []]),
        ),
      );

      if (isLatestUpdate) {
        setStationsBySearchQuery(Object.fromEntries(newStations));
      }
    })();

    return () => {
      isLatestUpdate = false;
    };
  }, [client, stationSearchQueries]);

  return stationsBySearchQuery;
};
