import { useQuery } from "urql";
import { CitiesByPrefectureIdDocument, PidanCitiesDocument } from "../graphql";

export const useCities = (prefectureId?: number) => {
  // prefectureId が変わるたびに refetch する必要があるので独立しておいたほうが良さそうという判断
  const [{ data: cities }] = useQuery({
    query: CitiesByPrefectureIdDocument,
    variables: { prefectureId: prefectureId ?? 0 },
    pause: !prefectureId,
  });
  return cities?.citiesByPrefectureId;
};

export const usePidanCities = (wardId?: number) => {
  // wardId が変わるたびに refetch する必要があるので独立しておいたほうが良さそうという判断
  const [{ data }] = useQuery({
    query: PidanCitiesDocument,
    variables: { wardId: wardId ?? 0 },
    pause: !wardId,
  });
  return data?.pidanCities.map((city) => ({
    id: Number(city.id.replace("City/", "")),
    name: city.name ?? "",
  }));
};
