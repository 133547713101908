import { ComponentProps, useMemo } from "react";

import { BuildingFormModal } from "../../../../../components/modals/BuildingFormModal";
import { usePidanCities } from "../../../../../hooks/useCities";
import { usePidanPrefectures } from "../../../../../hooks/usePrefectures";
import { usePidanWards } from "../../../../../hooks/useWards";

export const useBuildingPrefecturesAndCities = (
  currentPrefectureId: number | undefined,
  onPrefectureIdChange: (prefectureId: number | undefined) => void,
  onPrefectureIdBlur: () => void,
  currentWardId: number | undefined,
  onWardIdChange: (wardId: number | undefined) => void,
  onWardIdBlur: () => void,
  currentCityId: number | undefined,
  onCityIdChange: (cityId: number | undefined) => void,
  onCityIdBlur: () => void,
): Pick<
  ComponentProps<typeof BuildingFormModal>,
  "prefectureSelectProps" | "citySelectProps" | "wardSelectProps"
> => {
  const prefectures = usePidanPrefectures();
  const wards = usePidanWards(currentPrefectureId);
  const cities = usePidanCities(currentWardId);
  const currentPrefecture = useMemo(() => {
    return prefectures?.find(
      (prefecture) => prefecture.id === currentPrefectureId,
    )?.name;
  }, [currentPrefectureId, prefectures]);
  const currentWard = useMemo(() => {
    return wards?.find((ward) => ward.id === currentWardId)?.name;
  }, [wards, currentWardId]);
  const currentCity = useMemo(() => {
    return cities?.find((city) => city.id === currentCityId)?.name;
  }, [currentCityId, cities]);

  return {
    prefectureSelectProps: {
      currentPrefecture,
      prefectures: prefectures ?? [],
      onSelect: (prefecture) => {
        onPrefectureIdChange(prefecture.id);
        onPrefectureIdBlur();
      },
      onDelete: () => {
        onPrefectureIdChange(undefined);
        onPrefectureIdBlur();
      },
    },
    wardSelectProps: {
      currentWard,
      wards: wards ?? [],
      onSelect: (ward) => {
        onWardIdChange(ward.id);
        onWardIdBlur();
      },
      onDelete: () => {
        onWardIdChange(undefined);
        onWardIdBlur();
      },
    },
    citySelectProps: {
      currentCity,
      cities: cities ?? [],
      onSelect: (city) => {
        onCityIdChange(city.id);
        onCityIdBlur();
      },
      onDelete: () => {
        onCityIdChange(undefined);
        onCityIdBlur();
      },
    },
  };
};
